import { all_possible_controlnets } from '../data/magov1/data'
export function prepareSettings(settings) {
  if (!settings['controlnet_multimodel']) {
    settings['controlnet_multimodel'] = {}
  }
  for (const [model_version, possible_controlnets] of Object.entries(
    all_possible_controlnets
  )) {
    for (const controlnet of possible_controlnets) {
      if (!settings['controlnet_multimodel'][controlnet]) {
        settings['controlnet_multimodel'][controlnet] = {
          enabled: false,
          weight: 1,
          start: 0,
          end: 1,
          mode: 'global',
          source: 'global',
          resolution: -1,
          preprocess: 'global',
        }
      }
    }
  }
  return settings
}

export function removeNullKeys(obj) {
  // Create a new object to hold the cleaned entries
  const result = {}

  // Iterate over each property in the original object
  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    // If the value is an object and not null, recurse into it
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      const cleanedSubObject = removeNullKeys(value)
      // Only add the cleaned object if it's not empty after cleaning
      if (Object.keys(cleanedSubObject).length > 0) {
        result[key] = cleanedSubObject
      }
    } else if (value !== null) {
      // If the value is not null, add it to the result object
      result[key] = value
    }
    // Null values are implicitly ignored and not added to result
  })

  return result
}

export function clearControlnets(settings) {
  if (!settings['controlnet_multimodel']) {
    return settings
  }
  let result = settings
  let controlnet_multimodel = {}
  for (const [key, value] of Object.entries(
    settings['controlnet_multimodel']
  )) {
    if (value.enabled && value.weight != 0) {
      value.weight = parseFloat(value.weight)
      value.start = parseFloat(value.start)
      value.end = parseFloat(value.end)
      controlnet_multimodel[key] = value
    }
  }

  let model_version = settings['model_version']
  let possible_controlnets = all_possible_controlnets[model_version]
  console.log('clear cn', model_version, possible_controlnets)
  for (let key in controlnet_multimodel) {
    if (!possible_controlnets?.includes(key)) {
      controlnet_multimodel[key]['enabled'] = false
      controlnet_multimodel[key]['weight'] = parseFloat(0)
    }
  }

  result.controlnet_multimodel = controlnet_multimodel
  return result
}

export function stringifyOnLoad(settings) {
  for (const [key, value] of Object.entries(settings)) {
    if (key != 'controlnet_multimodel' && typeof value == 'object') {
      settings[key] = JSON.stringify(settings[key], null, 2)
    }
  }
  return settings
}

export function deStringifyOnSave(settings) {
  for (const [key, value] of Object.entries(settings)) {
    if (key != 'controlnet_multimodel' && typeof value == 'string') {
      // settings[key] = JSON.parse(settings[key])
      try {
        settings[key] = JSON.parse(settings[key])
      } catch {
        settings[key] = settings[key]
      }
    }
  }
  return settings
}
