import axios from 'axios'
import { backendUrl } from '../stores/urls'
import {
  prepareSettingsForSaving,
  clearControlnetsDict,
} from '../../controllers/settingsController'
export const renderLayer = async (parentLayer, frameRange, settings) => {
  console.log('framerange', frameRange)
  if (!parentLayer) {
    return
  }
  try {
    console.log('layer', parentLayer)

    settings = clearControlnetsDict(prepareSettingsForSaving(settings))
    settings.renderSettings[settings.renderEngine]['frame_range'] = frameRange

    const response = await axios.post(
      `${backendUrl}/assets/add_render`,
      {
        parent_layer_id: parentLayer?.id,
        frame_range: frameRange,
        render_settings: settings.renderSettings,
        sources: {
          video: settings.video_init_path,
          ipadapter_image_source: settings?.ipadapter_image_source,
        },
        render_engine: settings.renderEngine,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    console.log('renderLayer response', response.data)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
