<script>
  import { notificationMessage } from './stores/notification'
  export let duration = 5000 // Default duration to show popup
  import Cross from './icons/Cross.svelte'

  $: if ($notificationMessage?.text || $notificationMessage?.title) {
    setTimeout(() => {
      notificationMessage.set({})
    }, $notificationMessage?.duration || duration)
  }
</script>

{#if $notificationMessage?.text || $notificationMessage?.title}
  <div class="notification-message">
    <div class="notification-title">{$notificationMessage.title}</div>
    <div class="notification-text">{$notificationMessage.text}</div>
    <div
      class="close"
      on:click={() => {
        notificationMessage.set({})
      }}
    >
      <Cross />
    </div>
  </div>
{/if}

<style>
  .close {
    align-self: center;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  .notification-text {
    color: var(--text-secondary);
    font-family: 'Lato', sans-serif;
  }
  .notification-title {
    color: var(--text-primary);
    font-family: 'Lato', sans-serif;
  }
  .notification-message {
    position: absolute;
    left: calc(50% - 15vw);
    bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    padding: 20px 20px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: solid 4px var(--elements-tertiary-active);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: opacity 0.6s ease-in-out;
    z-index: 1000;
    background-color: rgba(13, 13, 13, 0.7);
    backdrop-filter: blur(10px);
    width: 30vw;
    height: 90px;
  }
</style>
