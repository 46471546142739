import { writable } from 'svelte/store'

export const selectedLayers = writable([])
export const activeLayer = writable(null)
export const timelines = writable([])
export const activeTimeline = writable(null)
export const timelineMaxFrames = writable(1000) //should basically adopt the longest layer length
export const timelineWidthPx = writable(1000) //timeline width in pixels. is basically what fits onto the screen. wider - scrolls
export const timelineScale = writable(100) //0-1. so bacially we scale max pixel size
export const timelineScaledWidthPx = writable(1000) //scaled width
export const timelineScrollOffset = writable(0) //timeline scroll position
export const settingsDiff = writable({})
export const timelineContainer = writable(null)
export const timelineControlsContainer = writable(null)
export const controlsWidth = 60
export const openModalId = writable(null);
export const videoUploadProgress = writable(null);
