<script>
  import { range_min, range_max } from '../../stores'
  let container // To reference the slider container
  let dragging = null // To track which notch is being dragged
  let startPos, startValue
  import { timelineScaledWidthPx } from '../stores/timeline'

  // Mouse down event to start dragging
  function handleMouseDown(event, notch) {
    dragging = notch
    startPos = event.clientX
    startValue = notch === '$range_min' ? $range_min : $range_max
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  // Mouse move event to drag the notch
  function handleMouseMove(event) {
    if (!dragging) return
    const deltaX = event.clientX - startPos
    const newValue = startValue + (deltaX / container.offsetWidth) * 100
    if (dragging === '$range_min') {
      $range_min = Math.min(Math.max(0, newValue), $range_max - 1) // Prevent crossing over $range_max
    } else {
      $range_max = Math.max(
        Math.min($timelineScaledWidthPx, newValue),
        $range_min + 1
      ) // Prevent crossing under $range_min
    }
  }

  function handleClick(event) {
    const rect = container.getBoundingClientRect()
    let leftPosition = rect.left
    const deltaX = event.clientX - leftPosition
    const newValue = (deltaX / container.offsetWidth) * 100
    
    const midValue = ($range_max + $range_min) / 2
    console.log(
      'got click',

      newValue,
      $range_min,
      $range_max,
      midValue
    )
    if (newValue < $range_min) {
      $range_min = Math.max(0, newValue)
    } else if (newValue > $range_max) {
      $range_max = Math.min($timelineScaledWidthPx, newValue)
    } else if ($range_min < newValue && newValue <= midValue) {
      $range_min = Math.max(0, newValue)
    } else if (midValue < newValue && newValue < $range_max) {
      $range_max = Math.min($timelineScaledWidthPx, newValue)
    }
  }

  // Mouse up event to stop dragging
  function handleMouseUp() {
    dragging = null
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
  }

  export let width
</script>

<div
  bind:this={container}
  class="slider"
  style="width: {width}px"
  on:click={handleClick}
>
  <div
    class="range-bar"
    style="left: {$range_min}%; right: {100 - $range_max}%;"
  ></div>
  <div
    class="notch"
    style="left: {$range_min}%;"
    on:mousedown={(event) => handleMouseDown(event, '$range_min')}
  ></div>
  <div
    class="notch"
    style="left: {$range_max}%;"
    on:mousedown={(event) => handleMouseDown(event, '$range_max')}
  ></div>
</div>

<style>
  .slider {
    appearance: none;
    position: relative;
    width: 100%;
    height: 10px;
    /* left: -10px; */
  }
  .range-bar {
    position: absolute;
    height: 10px;
    background: #363636; /* Range bar color, visible between the notches */
    top: 0;
    background-color: #363636; /* More visible range bar color */
  }
  .notch {
    position: absolute;
    margin-left: -10px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: var(--text-action200) transparent transparent transparent;
    cursor: pointer;
  }
</style>
