import axios from 'axios'
import { backendUrl } from '../stores/urls'

export const addLayer = async (timeline, layerType, parentLayer, data) => {
  console.log('addlayer data', data?.render_settings)
  if (!timeline) {
    return
  }
  try {
    const numLayers = timeline?.layers.length
    console.log('timeline', timeline)
    const response = await axios.post(
      `${backendUrl}/layers`,
      {
        timeline_id: timeline?.id,
        type: layerType,
        name: `RenderSettings${numLayers}`,
        data: data,
        parent_layer_order: parentLayer?.layer_order
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    console.log('addLayer response', response.data)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const updateLayer = async (layer) => {
  try {
    let response = await axios.put(
      `${backendUrl}/layers/${layer.id}`,
      {
        name: layer.name,
        data: layer.data,
        rating: layer.rating,
        layer_order: layer.layer_order
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const deleteLayer = async (layer) => {
  try {
    await axios.delete(`${backendUrl}/layers/${layer.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export const cloneLayer = async (layer) => {
  if (!layer) {
    return 
  }
  let layerId = layer.id
  try {
    const response = await axios.get(`${backendUrl}/layers/clone/${layerId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

// export const moveLayer = async (layer, offset) =>  {
//   layer.layer_order += offset
//   res = await updateLayer(layer)

// }