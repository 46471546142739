<script>
  import Cross from './icons/Cross.svelte'
  import { timelineScaledWidthPx } from './v02/stores/timeline'

  export let side = 'left' // Default to left
  export let isOpen = false
  export let sidebarType
  export let position = 0

  function toggleSidebar() {
    isOpen = !isOpen
  }

  function closeSidebar() {
    isOpen = false
  }

  // Adjust transform values based on the side and whether the sidebar is open
  $: sidebarTransform = isOpen
    ? 'translateX(0)'
    : side === 'left'
      ? 'translateX(-100%)'
      : 'translateX(100%)'
</script>

<div
  class="sidebar-bg {isOpen ? 'open' : ''}"
  style="width: calc(100vw - {$timelineScaledWidthPx + 100}px)"
  on:click={closeSidebar}
>
  <div
    class="sidebar {isOpen ? 'open' : ''} sidebar-{sidebarType}"
    style="transform: {sidebarTransform};"
    on:click|stopPropagation
  >
    <button on:click={toggleSidebar} class="close-button">
      <Cross />
    </button>
    <slot name="content"></slot>
  </div>
</div>

<style>
  .sidebar-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 50vw;
    height: 100%;
    display: none;
  }
  .sidebar-bg.open {
    display: block;
    z-index: 99;
  }

  .sidebar {
    top: 0;
    position: absolute;
    bottom: 0;
    background-color: rgba(13, 13, 13, 0.7);
    backdrop-filter: blur(10px);
    color: white;
    transition: transform 0.3s ease;
    z-index: 100;
  }

  .sidebar-projects {
    top: 52px;
    left: 0px;
  }

  .button-projects {
    margin-bottom: 0;
    margin-left: 20px;
    padding: 15px;
  }

  .sidebar-settings {
    top: 52px;
  }

  .close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
  }

  .close-button:active {
    background-color: transparent;
  }
</style>
