<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.499 3.25735C12.3037 3.06209 11.9871 3.06209 11.7919 3.25735L7.85254 7.19669L4.2061 3.55024C4.01083 3.35498 3.69425 3.35498 3.49899 3.55024C3.30373 3.7455 3.30373 4.06209 3.49899 4.25735L7.14544 7.90379L3.3066 11.7426C3.11134 11.9379 3.11134 12.2545 3.3066 12.4497C3.50186 12.645 3.81845 12.645 4.01371 12.4497L7.85254 8.6109L11.9843 12.7426C12.1795 12.9379 12.4961 12.9379 12.6914 12.7426C12.8866 12.5474 12.8866 12.2308 12.6914 12.0355L8.55965 7.90379L12.499 3.96445C12.6943 3.76919 12.6943 3.45261 12.499 3.25735Z"
    fill="#96959C"
  />
</svg>
