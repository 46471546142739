<script>
  import { writable } from 'svelte/store'
  import { setContext } from 'svelte'
  import { settings } from '../stores/renderSettings'
  import { activeLayer } from '../v02/stores/timeline'

  const activeTab = writable(0)
  let tabs = writable([])
  export let activeTabs

  const TAB_CONTEXT_KEY = 'tab-context'

  function registerTab(label) {
    tabs.update((current) => [...current, label])
    return $tabs.length - 1 // Returns the new tab index
  }

  setContext(TAB_CONTEXT_KEY, {
    registerTab,
    activeTab,
  })

  function isLabelInList(value, list) {
    if (!list) {
      return false
    }
    return list.some((item) => item.label === value)
  }
  let oldTabs = activeTabs
  function handleTabsChange(activeTabs) {
    if (oldTabs != activeTabs || !$activeTab) {
      $activeTab = getFirstMatchingLabelIndex($tabs, activeTabs)
    }
    oldTabs = activeTabs
  }
  function getFirstMatchingLabelIndex(labels, dictList) {
    if (!labels || !dictList) {
      console.log('activetab !labels || !dictList', 0)
      return 0
    }
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i]
      if (dictList.some((item) => item.label === label)) {
        return i
        console.log('activetab', i)
      }
    }
    console.log('activetab 0', 0)
    return 0
  }
  $: $tabs, $settings, $activeLayer, handleTabsChange(activeTabs)
</script>

<div class="tab-container">
  <div class="horiz-container tabs">
    {#each $tabs as tab, index}
      {#if isLabelInList(tab, activeTabs)}
        <button
          on:click={() => ($activeTab = index)}
          class:active={$activeTab === index}
        >
          {tab.split('_')[0]}
        </button>
      {/if}
    {/each}
  </div>

  <div class="content">
    <slot />
  </div>
</div>

<style>
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: right;
    box-sizing: border-box;
    position: fixed;
    background-color: var(--surface-primary);
    font-family: 'Lato', sans-serif;
    font-size: 15px;
  }

  .content {
    flex-grow: 1;
    padding: 20px 40px;
    margin-top: 60px;
    height:100vh;
  }

  button:first-child {
    margin-left: 20px;
  }

  button {
    border: none;
    background-color: transparent;
    color: var(--text-secondary);
    text-align: center;
    height: 52px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 6px;
    transition: background-color 0.3s;
    white-space: nowrap;
    text-transform: capitalize;
    border-radius: 0;
  }

  .active,
  button:active {
    color: var(--text-primary);
    border-bottom: 1px solid var(--text-action200);
    background-color: transparent;
  }

  button:hover {
    color: var(--text-primary);
  }

  /* Overall layout container */
  .tab-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    /* max-height: calc(90vh - 220px); */
    overflow: auto;
  }

  .tabs {
    z-index: 300;
    height: 70px;
    justify-content: space-between;
  }

  .tabs > button:last-child:after {
    content: '';
    display: block;
    width: 50px;
    position: absolute;
    background-color: var(--surface-primary);
    left: 100%;
    top: 0;
    bottom: 0;
  }
</style>
