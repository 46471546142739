<script>
  import { settingsDiff } from './v02/stores/timeline'
  import Inputs from './SettingsUI/Inputs.svelte'
  import { settingLabels, settingTypes } from './data/magov1/data'
</script>

<div class="vert-container diffs" style="width: 40vw; ">
  {#if $settingsDiff}
    <div class="horiz-container" style="width: 100%;">
      {#if $settingsDiff?.names}
        <div style="width: 40%; margin: 5px;">
          <Inputs
            input_type="text"
            label="Name"
            bind:value={$settingsDiff.names.left}
            disabled={true}
          />
        </div>
        <div style="width: 40%; margin: 5px;">
          <Inputs
            input_type="text"
            label="Name"
            bind:value={$settingsDiff.names.right}
            disabled={true}
          />
        </div>
      {/if}
    </div>
    <div
      class="vert-container"
      style=" overflow-y: auto; overflow-x: hidden; max-height: calc(89vh - 80px); justify-content: start;"
    >
      <div
        style="display: flex; flex-direction: column; justify-content: start;"
      >
        {#if $settingsDiff?.changes?.length > 0}
          {#each $settingsDiff.changes as diff}
            <div class="horiz-container" style="width: 100%;">
              <div style="width: 40%; margin: 5px;">
                <Inputs
                  input_type={'textarea'}
                  label={settingLabels[diff.name] || diff.name}
                  bind:value={diff.left}
                  disabled={true}
                />
              </div>
              <div style="width: 40%; margin: 5px;">
                <Inputs
                  input_type={'textarea'}
                  label={settingLabels[diff.name] || diff.name}
                  bind:value={diff.right}
                  disabled={true}
                />
              </div>
            </div>
          {/each}
        {:else}
          <div class="horiz-container" style="width: 100%;">
            Select two render layers to compare settings.
          </div>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  .diffs {
    font-family: 'Lato', sans-serif;
    text-align: left;
  }
</style>
