<!-- Unused for now, hardcoded to log in with default user -->
<script>
  import { login, getToken } from '../services/authService'
  import { onMount } from 'svelte'
  import { getUser } from '../services/authService'
  import { isOpenSettings } from '../stores/renderSettings'
  import { writable } from 'svelte/store'
  import { isLoggedIn } from '../stores/auth'
  import { projects } from '../stores/project'
  import { queue } from '../../stores'
  import { activeProject } from '../stores/project'
  import { activeLayer, activeTimeline } from '../stores/timeline'
  import { fetchProjectMany } from '../services/projectService'

  let email = ''
  let password = ''

  async function handleLogin() {
    if (!email){
      handleLoginDefault()
      return
    }
    try {
      await login(email, password)
      // alert('Login successful')
      $isLoggedIn = true
    } catch (error) {
      // alert('Login failed')
      $isLoggedIn = false
    }
  }

  function handleLogout() {
    localStorage.removeItem('token')

    $isLoggedIn = false
    $projects = []
    $queue = []
    $activeProject = undefined
    $activeLayer = undefined
    $activeTimeline = undefined
  }

  let defaultEmail = 'admin@mago.studio'
  let defaultPassword = 'ImACoolMagoAdmin'

  async function handleLoginDefault() {
    try {
      await login(defaultEmail, defaultPassword)
      await fetchProjectMany()
      if ($projects.length > 0) {
        $activeProject = $projects[0]
        $activeTimeline = $activeProject.timeline
      }
      // alert('Login successful')
      $isLoggedIn = true
    } catch (error) {
      // alert('Login failed')
      $isLoggedIn = false
    }
  }

  onMount(async () => {
    if (localStorage.getItem('token')) {
      let user = await getUser()
      console.log('Already logged in.')
      console.log('user', user)
      if (!user) {
        $isLoggedIn = false
        localStorage.removeItem('token')
      } else {
        $isLoggedIn = true
      }
    } else {
      $isLoggedIn = false
    }
    if (!$isLoggedIn) {
      // await login(defaultUsername, defaultPassword)
      // console.log('Default user logged in successfully')
    }

    // always login to avoid handling token expiration for now

    // const token = getToken();

    // if (!token) {
    //     try {
    //         await login(defaultUsername, defaultPassword);
    //         console.log('Default user logged in successfully');
    //     } catch (error) {
    //         console.error('Login failed', error);
    //     }
    // } else {
    //     console.log('Already logged in');
    // }
  })
</script>

<main style="margin:10px;">
  {#if !$isLoggedIn}
    <div style="display: flex; align-items: center; gap: 10px;">
      <input
        type="text"
        placeholder="Email"
        bind:value={email}
        style="padding: 5px; width: 150px;"
      />
      <input
        type="password"
        placeholder="Password"
        bind:value={password}
        style="padding: 5px; width: 150px;"
      />
      <button
        class="mago-button"
        on:click={handleLogin}
        style="padding: 5px 10px; cursor: pointer;"
      >
        Login
      </button>
 
    </div>
  {:else}
    <button
      on:click={handleLogout}
      style="padding: 5px 10px; cursor: pointer;"
      class="mago-button"
    >
      Logout
    </button>
  {/if}
</main>
 
