<script>
  import Clip from './Clip.svelte'
  import { writable } from 'svelte/store'
  import { deleteClip } from '../services/clipService'
  import { deleteLayer } from '../services/layerService'
  import { uploadFile } from '../services/assetService'
  import {
    hasFrames,
    calcFrames,
    downloadVideo,
    downloadFrames,
  } from '../helpers/layer'

  import { timelines, activeTimeline, selectedLayers } from '../stores/timeline'
  import Sparkles from '../../icons/Sparkles.svelte'
  import Like from '../../icons/Like.svelte'
  export let layer
  export let onclick
  export let onKeyPress
  export let onStylize
  export let onClone
  export let compType = 'layer'
  import { settings } from '../../stores/renderSettings'
  import { controlsWidth } from '../stores/timeline'

  import LayerModal from '../../LayerModal.svelte'
  import LayerRenderStatus from './LayerRenderStatus.svelte'
  import { currentFrame } from '../../stores'
  import { updateLayer } from '../services/layerService'

  export let onDragStart;
  export let onDrop;
  export let onDragOver;

  async function handleDeleteClip(clip) {
    layer.clips = await deleteClip(layer.clips, clip)
    $activeTimeline = $timelines[0]
  }

  async function handleDeleteLayer(layer) {
    if (confirm(`Are you sure you want to delete layer ${layer.name}?`)) {
      deleteLayer(layer)
      $activeTimeline.layers = $activeTimeline.layers.filter(
        (c) => c.id !== layer.id
      )
    }
  }

  async function handleUpload(event) {
    console.log('layer', layer)
    layer = await uploadFile(event, null, layer)
    console.log('layer', layer)
  }

  function isDictInList(list, dict) {
    return list.some((item) =>
      Object.keys(dict).every((key) => item[key] === dict[key])
    )
  }

  let layerElement
  function focusLayer() {
    layerElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  function updateRenderLabel(settings) {
    $renderEngine = layer?.data?.render_settings?.renderEngine || ''
    console.log('updateRenderLabel', $renderEngine)
  }

  // let likeStyle = writable(getRating())
  function onLike() {
    if (!layer.rating) {
      layer.rating = 0
    }
    let newRating = layer.rating + 1
    if (newRating > 2 || !newRating) {
      newRating = 0
    }
    layer.rating = newRating
    // $likeStyle = getRating()
    updateLayer(layer)
  }

  // function getRating() {
  //   if (layer.rating == 0 || !layer.rating) {
  //     return 'no-rating'
  //   }
  //   if (layer.rating == 1) {
  //     return 'liked'
  //   }
  //   if (layer.rating == 2) {
  //     return 'disliked'
  //   }
  // }

  const renderEngine = writable('magov1')
  $: updateRenderLabel($settings)
  // $: layerElement, ()=>{$likeStyle = getRating()}
 
</script>

<div
  draggable="true"
  on:dragstart={() => onDragStart(layer.id)}
  on:dragover={onDragOver}
  on:drop={() => onDrop(layer.id)}
  bind:this={layerElement}
  on:keypress={onKeyPress}
  style="padding-left:0; padding-right: 0; display: flex; cursor: grab;"
  id="layer"
  on:click={() => {
    focusLayer()
    onclick(layer)
  }}
  on:dblclick={() => {
    focusLayer()
    onclick(layer)
    $currentFrame = layer?.clips[0].data?.timeline_offset || 0
  }}
>
  <div class="buttons">
    <div style="width: {controlsWidth}px; " on:click|stopPropagation>
      <div
        class="horiz-container"
        style="height: {(layer?.clips.length || 1) *
          68}px; z-index: 100; position: relative; justify-content: space-between; border: 1px solid transparent; align-items: center; padding: 5px;"
      >
        {#if layer?.type == 'RENDER'}
          <span class="onhover {layer.rating == 0 ? 'no-rating' : layer.rating == 1 ? 'liked' : 'disliked'}" on:click={() => onLike()}
            ><Like /></span
          >
        {/if}
        {#if layer?.type == 'IMAGE'}
          <span
            class="onhover"
            style="transform: translateX(-3px);"
            on:click={() => onStylize()}><Sparkles /></span
          >
        {/if}

        <!-- <span class="onhover" on:click={() => handleDeleteLayer(layer)}
          ><Garbage /></span
        > -->
        {#if layer?.type == 'RENDER'}
          <LayerModal
            id={layer?.id}
            onDownloadFrames={(layer?.type == 'RENDER' && hasFrames(layer)) ||
            layer?.clips[0]?.asset?.data?.parent_render
              ? () => downloadFrames(layer)
              : null}
            onDownloadVideo={layer?.type == 'RENDER' &&
            hasFrames(layer) &&
            layer?.clips[0]?.asset?.status == 'FINISHED' &&
            calcFrames(layer?.clips[0]?.asset?.frame_range) != 1 &&
            !layer?.clips[0]?.asset?.data?.parent_render
              ? () => downloadVideo(layer)
              : null}
            onDelete={() => handleDeleteLayer(layer)}
            onDuplicate={() => onClone()}
            {layer}
          />
        {:else}
          <LayerModal onDelete={() => handleDeleteLayer(layer)} />
        {/if}
      </div>
    </div>
    <div
      class="layer-names"
      style="margin-left: {controlsWidth}px; margin-bottom: 2px;"
    >
      <div class="horiz-container" style="white-space: nowrap;">
        <LayerRenderStatus {layer} />
        <div class="layer-name text-ellipsis" style="max-width: 300px;">
          {layer.layer_order}
          {layer.name}
        </div>
      </div>
    </div>
  </div>
  <div
    id="layer-itself"
    class="container {isDictInList($selectedLayers, layer)
      ? 'selected'
      : 'inactive'}"
    style="width:100%;"
  >
    <div class="{layer?.type == 'RENDER' ? 'vertical' : 'horizontal'}  ">
      {#if layer.clips?.length != 0}
        {#each layer.clips as clip}
          <Clip bind:clip {layer} onDeleteClip={handleDeleteClip} />
        {/each}
      {/if}
    </div>
    {#if layer.type == 'IMAGE' && layer.clips?.length == 0}
      <div class="setting">
        <label class="mago-button" style="width: 170px; ">
          Add Video ➕
          <input
            type="file"
            class="hidden"
            id="file"
            accept="video/*"
            on:change={handleUpload}
          />
        </label>
      </div>
    {/if}
  </div>
</div>

<style>
  .disliked {
    transform: scaleY(-1);
    fill: red;
    stroke-width: 1;
    stroke: red;
    color: red;
  }

  .liked {
    fill: green;
    stroke-width: 1;
    stroke: green;
    color: green;
  }

  .container.inactive {
    background-color: rgba(52, 52, 52, 0.5);
  }

  .layer-names {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 10;
  }
  .layer-name {
    background-color: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(5px);
    margin: 3px;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
  }

  .onhover:hover {
    text-decoration: underline;
    cursor: pointer;
    & > svg > path {
      fill: var(--text-hover);
      transition: 200ms;
    }
  }

  .container.selected {
    background-color: var(--text-action200);
    border: solid 2px var(--text-action200);
  }

  .hidden {
    display: none; /* Hide the actual file input */
  }

  .button-container {
    position: relative;
    margin-bottom: 5px; /* Optional: space between main containers */
    border-radius: 8px;
    border: solid 2px transparent;
  }

  .container {
    position: relative;
    margin-bottom: 5px; /* Optional: space between main containers */
    border-radius: 8px;
    border: solid 2px transparent;
  }

  .horizontal {
    min-height: 68px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .vertical {
    min-height: 68px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons {
    background-color: var(--surface-primary);
    position: sticky;
    left: 0;
    z-index: 10;
  }
</style>
