export const settingLabels = {
  video_init_path: 'Video Path:',
  fps: 'Frames Per Second:',
  width: 'Custom Width:',
  height: 'Custom Height:',
  frame_range: 'Frame Range:',
  nth_frame: 'Select Every Nth Frame:',
  cn_depth_strength: 'Depth Strength:',
  cn_depth_start: 'Depth Start Percent:',
  cn_depth_end: 'Depth End Percent:',
  cn_depth_resolution: 'Depth Resolution:',
  cn_tile_strength: 'Tile Strength:',
  cn_tile_start: 'Tile Start Percent:',
  cn_tile_end: 'Tile End Percent:',
  cn_tile_resolution: 'Tile Resolution:',
  cn_canny_strength: 'Canny Strength:',
  cn_canny_start: 'Canny Start Percent:',
  cn_canny_end: 'Canny End Percent:',
  cn_canny_resolution: 'Canny Resolution:',
  prompt: 'Positive Prompt:',
  negative_prompt: 'Negative Prompt:',
  ipadapter_image_source: 'IPAdapter Image Source:',
  ipadapter_strength: 'Image Weight',
  seed: 'Seed:',
  steps: 'Steps:',
  cfg: 'CFG Scale:',
  sampler: 'Sampler Name:',
  scheduler: 'Scheduler:',
  denoise: 'Denoise:',
  steps_upscale: 'Upscale Steps:',
  denoise_upscale: 'Upscale Denoise:',
  output_folder: 'Output Folder:',
  clip_skip: 'CLIP Skip Layer:'
};

export const settingTypes = {
  video_init_path: 'text',
  fps: "float", 
  width: 'int',
  height: 'int',
  frame_range: 'Frame Range:',
  nth_frame: 'int',
  cn_depth_strength: "float", 
  cn_depth_start: "float", 
  cn_depth_end: "float", 
  cn_depth_resolution: 'int',
  cn_tile_strength: "float", 
  cn_tile_start: "float", 
  cn_tile_end: "float", 
  cn_tile_resolution: 'int',
  cn_canny_strength: "float", 
  cn_canny_start:"float", 
  cn_canny_end: "float", 
  cn_canny_resolution: 'int',
  prompt: 'text',
  negative_prompt: 'text',
  ipadapter_image_source: 'hidden',
  ipadapter_strength: 'float',
  seed: 'int',
  steps: 'int',
  cfg: "float", 
  sampler: 'text',
  scheduler: 'text',
  denoise: "float", 
  steps_upscale: 'int',
  denoise_upscale: "float", 
  output_folder: 'text',
  clip_skip: 'int',
  model_path: 'hidden',
  vae_path: 'hidden'
};

export const defaultSettings = {
  video_init_path: '',
  fps: 0, //0 to keep original fps
  width: 768,
  height: 768,
  frame_range: [0,1],
  nth_frame: 1,
  cn_depth_strength: 0.5, 
  cn_depth_start: 0, 
  cn_depth_end: 1, 
  cn_depth_resolution: 1024,
  cn_tile_strength: 0.15, 
  cn_tile_start: 0, 
  cn_tile_end: 1, 
  cn_tile_resolution: 1024,
  cn_canny_strength: 0.2, 
  cn_canny_start:0, 
  cn_canny_end: 1, 
  cn_canny_resolution: 1024,
  prompt: 'a cat',
  negative_prompt: 'bad quality, low quality',
  ipadapter_image_source: '',
  ipadapter_strength: 1,
  seed: 42,
  steps: 4,
  cfg: 2, 
  sampler: 'dpmpp_sde',
  scheduler: 'karras',
  denoise: 1, 
  steps_upscale: 4,
  denoise_upscale: 0.55, 
  output_folder: '', // whatever goes after ./projects 
  clip_skip: 2,
  model_path: "dreamshaperXL_v21TurboDPMSDE.safetensors",
  vae_path: "sdxl_vae.safetensors",
};

const mainLabels = [
  // 'video_init_path',
  // 'fps',
  'width',
  'height',
  'frame_range',
  // 'nth_frame',
  'prompt',
  'negative_prompt',
  'ipadapter_image_source',
  'ipadapter_strength',
  'seed',
  'steps',
  'cfg',
  // 'sampler',
  // 'scheduler',
  'denoise',
  'steps_upscale',
  'denoise_upscale',
  // 'output_folder',
  'clip_skip'
]

const ControlNetLabels = [
'cn_depth_strength',
  'cn_depth_start',
  'cn_depth_end',
  'cn_depth_resolution',
  'cn_tile_strength',
  'cn_tile_start', 
  'cn_tile_end',
  'cn_tile_resolution',
  'cn_canny_strength',
  'cn_canny_start',
  'cn_canny_end',
  'cn_canny_resolution',
]

export const settingsTabs = [
  {
    label: 'main_magov2',
    settings: mainLabels,
  },
  {
    label: 'controlnets_magov2',
    settings: ControlNetLabels,
  }
]
