<script>
  export let onOpen = null
  export let onClose = null
  export let buttonStyle = ''
  export let modalStyle = ''
  export let cancelButton = true
  export let isOpen = false
  export let showButton = true
  export let saveOnClickOutside = true
  import Cross from '../../icons/Cross.svelte'

  const openModal = async () => {
    await onOpen()
    isOpen = true
  }

  const close = async (save = true) => {
    if (save) {
      await onClose()
    }
    isOpen = false
  }

  function handleKeydown(event) {
    if (event.key === 'Escape') {
      close(false)
    }
  }
</script>

<main>
  {#if showButton}
    <button on:click={openModal} style={buttonStyle} class="mago-button"
      ><slot name="button"></slot></button
    >
  {/if}
  <div
    class="modal {isOpen ? 'open' : ''}"
    on:click={() => close(saveOnClickOutside)}
    on:keydown={handleKeydown}
  >
    <div
      class="modal-content"
      style={modalStyle}
      on:click|stopPropagation
      on:keydown={handleKeydown}
    >
      <slot name="main"></slot>
      {#if cancelButton}
        <div
          on:click={() => close(false)}
          class="close"
          on:keydown={handleKeydown}
        >
          <Cross />
        </div>
      {/if}
      <button on:click={() => close(true)} class="mago-button" style='margin-top: 30px;'
        ><slot name="closeButton"></slot></button
      >
    </div>
  </div>
</main>

<style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }

  .modal.open {
    display: block;
    z-index: 500;
  }

  .modal-content {
    position: relative;
    margin: auto;
    padding: 20px;
    width: 50%;
    margin-top: 20px;
    z-index: 500;
    background-color: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(5px);
    color: white;
    border-radius: 10px;
  }

  .close {
    align-self: center;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
</style>
