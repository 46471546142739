<script>
  import Garbage from '../../icons/Garbage.svelte'
  import { backendUrl } from '../../stores.js'
  import { notificationMessage } from '../../stores/notification.js'
  import ArrowDown from '../../icons/ArrowDown.svelte'
  import axios from 'axios'
  import ProgressBar from './ProgressBar.svelte'
  export let queueItem

  async function deleteSettings(q) {
    let queueItemId = q.id
    {
      if (q.show_done ? true : confirm(`Are you sure you want to delete ${q.id}?`)) {
        const response = await fetch(`${backendUrl}/queue/${queueItemId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        if (response.ok) {
          $notificationMessage = {
            title: 'Render removed from queue.',
            text: `Removed  ${q.project_name} render for frames [${q.frame_range}]`,
          }
          console.log('Moved render settings to cancelled.')
        } else {
          alert('Failed to delete render.')
        }
      }
    }
  }

  async function move(q, offset) {
    let queueItemId = q.id
    const response = await axios.put(
      `${backendUrl}/queue/${queueItemId}`,
      {
        order: q.order + offset,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
  }
</script>

<div
  class="horiz-container"
  style="width: 100%; height: 42px;  align-items: center;"
>
  {#if queueItem.status == 'PENDING'}
    <div class="horiz-container">
      <span
        class="onhover"
        style="transform: rotate(180deg); margin: auto; width: 16px;"
        on:click={() => move(queueItem, -1)}
      >
        <ArrowDown />
      </span>
      <span
        class="onhover"
        style="margin: auto; width: 16px;"
        on:click={() => move(queueItem, 1)}
      >
        <ArrowDown />
      </span>
    </div>
  {:else}
    <div class="horiz-container" style="width: 54px;"></div>
  {/if}
  <div class="vert-container" style='align-items: flex-start; height: 50px; width: 100%;'>
    <div>{(queueItem.project_name || 'Untitled') + ' - ' + (queueItem.layer_name || 'Untitled')}</div>
    <div style="font-size: 13px; color: var(--text-secondary); ">
      Frames: {queueItem.frame_range[0]}-{queueItem.frame_range[1]}
    </div>
  </div>
  {#if (queueItem.status == 'FINISHED' || queueItem.progress>=100) && queueItem.status != 'ERROR'}
    <div style="width: 100px;">{queueItem.progress}%</div>
    <div
      style="background-color: rgb(0, 131, 0); height: 10px; width: 50%;border-radius: 8px;"
    ></div>
  {/if}
  {#if queueItem.status == 'INPROGRESS' && queueItem.progress<100}
    <div style="width: 100px;">{queueItem.progress}%</div>
    <ProgressBar
      progress={queueItem.progress}
      style="height: 10px; width: 50%;"
      animateGray={true}
    />
  {/if}
  {#if queueItem.status == 'PENDING'}
    <div style="width: 100px;">Waiting</div>
    <div
      style="background-color: rgb(20,20,20); height: 10px; width: 50%;border-radius: 8px;"
    ></div>
  {/if}
  {#if queueItem.status == 'ERROR'}
    <div class="setting-input">Error</div>
    <div
      style="background-color: rgb(200,0,0); height: 10px; width: 50%;border-radius: 8px;"
    ></div>
  {/if}

  <span
    class="onhover"
    style="width: 50px;"
    on:click={() => deleteSettings(queueItem)}><Garbage /></span
  >
</div>

<style>
  .onhover:hover {
    text-decoration: underline;
    cursor: pointer;
    & > svg > path {
      fill: var(--text-hover);
      transition: 200ms;
    }
  }
</style>
