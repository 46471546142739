<script>
  import { onMount } from 'svelte'
  import { currentFrame, videoIsPlaying, videoTime } from './stores'
  import { activeLayer } from './v02/stores/timeline'

  export let src
  let type = 'video/mp4'
  export let style
  export let clip

  const FPS = 25
  let actualFPS = FPS

  let videoElement

  function getBoundingClientRect() {
    return videoElement.getBoundingClientRect()
  }

  function getClipOffsetFrames() {
    return clip?.data?.timeline_offset || 0
  }

  export { getBoundingClientRect }

  function getFps() {
    // console.log('fps actualFPS',  $activeLayer?.clips[0]?.asset?.data?.fps, actualFPS)
    return $activeLayer?.clips[0]?.asset?.data?.fps || FPS
  }

  // Metadata event: fires when video metadata is loaded
  const onLoadedMetadata = () => {
    console.log('Video metadata loaded, duration:', videoElement.duration)
    // on video change (layer selection)
    videoElement.currentTime =
      ($currentFrame - getClipOffsetFrames()) / actualFPS
  }

  // Progress event: fires when video data is buffered
  const onProgress = () => {
    const buffered = videoElement.buffered
    if (buffered.length > 0) {
      console.log('Buffered up to:', buffered.end(0), 'seconds')
    }
  }

  function setCurrentProgress(currentFrame) {
    console.log('setCurrentProgress', currentFrame, getClipOffsetFrames())
    actualFPS = getFps()

    $videoTime = (currentFrame - getClipOffsetFrames()) / actualFPS //fps
  }

  function setCurrentTime(videoTime) {
    actualFPS = getFps()
    if (!videoElement) {
      return
    }
    // console.log('upd currentTime to ', videoTime)
    if (!$videoIsPlaying) {
      videoElement.currentTime = videoTime
    } else {
      $currentFrame = Math.floor(videoTime * actualFPS) + getClipOffsetFrames()
    }
  }

  $: setCurrentProgress($currentFrame)
  $: setCurrentTime($videoTime)

  function setIsPlaying(videoIsPlaying) {
    if (!videoElement) {
      return
    }
    if (videoIsPlaying) {
      videoElement.play()
    } else {
      videoElement.pause()
    }
  }

  $: setIsPlaying($videoIsPlaying)

  function handleTimeUpdate(event) {
    const newTime = event.target.currentTime
    $videoTime = newTime //fps
  }

  function stopOnShorterEnd() {
    $videoIsPlaying = false
  }

  const jwtToken = localStorage.getItem('token')

  onMount(() => {
    // Optional: Additional setup if needed
  })
</script>

<video
  bind:this={videoElement}
  src={`${src}?token=${jwtToken}`}
  {type}
  playsinline
  preload="metadata"
  on:click={(e) => e.preventDefault()}
  on:loadedmetadata={onLoadedMetadata}
  on:progress={onProgress}
  on:timeupdate={handleTimeUpdate}
  on:ended={stopOnShorterEnd}
  {style}
>
  Your browser does not support the video tag.
</video>
