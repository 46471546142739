<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.8 7.40741C17.4669 5.99165 17 3 17 3C17 3 16.5331 5.99165 15.2 7.40741C13.9693 8.71444 11 9.74074 11 9.74074C11 9.74074 13.9769 10.9488 15.2 12.3333C16.5489 13.8602 17 17 17 17C17 17 17.4511 13.8602 18.8 12.3333C20.0231 10.9488 23 9.74074 23 9.74074C23 9.74074 20.0307 8.71444 18.8 7.40741Z"
    fill="#575757"
  />
  <path
    d="M10.2 15.463C9.31128 14.3506 9 12 9 12C9 12 8.68872 14.3506 7.8 15.463C6.97953 16.4899 5 17.2963 5 17.2963C5 17.2963 6.98458 18.2455 7.8 19.3333C8.69926 20.5331 9 23 9 23C9 23 9.30074 20.5331 10.2 19.3333C11.0154 18.2455 13 17.2963 13 17.2963C13 17.2963 11.0205 16.4899 10.2 15.463Z"
    fill="#575757"
  />
  <path
    d="M7.55 5.83333C6.77237 4.9232 6.5 3 6.5 3C6.5 3 6.22763 4.9232 5.45 5.83333C4.73209 6.67357 3 7.33333 3 7.33333C3 7.33333 4.73651 8.10993 5.45 9C6.23685 9.98159 6.5 12 6.5 12C6.5 12 6.76315 9.98159 7.55 9C8.26349 8.10993 10 7.33333 10 7.33333C10 7.33333 8.26791 6.67357 7.55 5.83333Z"
    fill="#575757"
  />
</svg>
