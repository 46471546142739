import { writable } from 'svelte/store'
import {
  getInitSettings,
  onLoadSettings,
} from '../../controllers/settingsController'

export const settings = writable(onLoadSettings(getInitSettings()))
export const settingsOpen = writable(false)
export const isOpenSettings = writable(false)
export const isOpenSettingsDiff = writable(false)
