<script>
  import {
    activeProject,
    projects,
    showCreateModal,
    showImportProjectModal,
    importProjectModalIsLoading,
  } from './v02/stores/project'
  import {
    // openCreateModal,
    selectProject,
    deleteProject,
    fetchProjectMany,
    fetchProjectOne,
    updateProject,
    createProject,
    importProject,
  } from './v02/services/projectService'
  import Cross from './icons/Cross.svelte'
  import { onMount } from 'svelte'
  import Modal from './v02/components/Modal.svelte'

  let newProjectName = ''
  let newProjectDescription = ''
  let importProjectFolder = ''

  async function Mount() {
    await fetchProjectMany()
    $activeProject = projects[0]
    await selectProject($activeProject)
  }

  onMount(Mount)
</script>

<!-- Project Creation Modal -->
<Modal
  onOpen={async () => {
    await fetchProjectOne($activeProject.id)
  }}
  modalStyle="margin-top: 100px"
  onClose={() => createProject(newProjectName, newProjectDescription)}
  buttonStyle=""
  showButton={false}
  isOpen={$showCreateModal}
  saveOnClickOutside={false}
>
  <div slot="main" class="content">
    <div class="setting">
      <label>
        Name:
        <input
          type="text"
          bind:value={newProjectName}
          required
          class="setting-input form-control"
        />
      </label>
    </div>
    <!-- <div class="setting">
      <label>
        Description:
        <textarea
          bind:value={newProjectDescription}
          class="setting-input form-control"
        ></textarea>
      </label>
    </div> -->
  </div>
  <div slot="closeButton">Create</div>
</Modal>

<!-- Project Import Modal -->
<Modal
  onClose={() => importProject(importProjectFolder)}
  buttonStyle=""
  modalStyle="margin-top: 100px"
  showButton={false}
  isOpen={$showImportProjectModal}
  saveOnClickOutside={false}
>
  <div slot="main">
    <div class="settings-container">
      <div class="setting">
        <label>
          Project folder:
          <input
            type="text"
            bind:value={importProjectFolder}
            required
            class="setting-input form-control"
          />
        </label>
      </div>
    </div>
  </div>

  <div slot="closeButton">
    {#if $importProjectModalIsLoading}
      Importing ...
    {:else}
      Import
    {/if}
  </div>
</Modal>

<style>
  .content {
    text-align: left;
  }

  .form-control {
    margin-top: 5px;
    margin-bottom: 15px; /* Spacing between form elements */
    width: 100%; /* Full width to ensure alignment */
  }

  .setting {
    width: 100%;
  }
</style>
