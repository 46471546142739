<script>
  import {
    activeProject,
    projects,
    showCreateModal,
    showImportProjectModal,
  } from './v02/stores/project'
  import {
    selectProject,
    deleteProject,
    fetchProjectMany,
  } from './v02/services/projectService'
  import Cross from './icons/Cross.svelte'
  import { onMount } from 'svelte'

  async function Mount() {
    await fetchProjectMany()
    $activeProject = projects[0]
    await selectProject($activeProject)
  }

  function openCreateModal() {
    showCreateModal.update((a) => !a)
    showCreateModal.update((a) => true)
    //  weird hack to make sure modal opens
  }

  function openImportModal() {
    showImportProjectModal.update((a) => !a)
    showImportProjectModal.update((a) => true)
  }

  onMount(Mount)
</script>

<div class="sidebar">
  <button class="mago-button project-btn" on:click={openCreateModal}
    >Create Project</button
  >
  <!-- <button class="mago-button project-btn" on:click={openImportModal}
    >Import Project</button
  > -->
  {#each $projects as project}
    <div class="project-item">
      <button
        class="mago-button project-btn text-ellipsis"
        on:click={() => selectProject(project)}
        class:selected={project === $activeProject}
      >
        {project.name}
      </button>
      <button
        class="mago-button delete-btn"
        on:click={() => deleteProject(project.id)}
      >
        <Cross />
      </button>
    </div>
  {/each}
</div>

<style>
  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: calc(100vh - 51px);
    overflow:auto;
  }
  .project-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 300px;
  }
  .project-btn {
    text-align: left;
    width: 100%;
  }

  .delete-btn {
    margin-left: 10px;
    color: white;
    padding: 10px 10px;
  }

  .selected {
    background-color: #515151;
  }
</style>
