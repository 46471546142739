import {
  showCreateModal,
  showProjectSidebar,
  activeProject,
  projects,
  showImportProjectModal,
  importProjectModalIsLoading,
} from '../stores/project'
import axios from 'axios'
import { backendUrl } from '../stores/urls'
import { get } from 'svelte/store'
import { activeLayer, selectedLayers } from '../stores/timeline'

export const openCreateModal = () => {
  showCreateModal.set(true)
  console.log('showCreateModal', get(showCreateModal))
}

export const closeCreateModal = () => {
  showCreateModal.set(false)
  //   showProjectSidebar.set(false);
}

export const selectProject = async (project) => {
  console.log('project', project)
  if (!project?.id) {
    return
  }
  project = await fetchProjectOne(project.id)
  activeProject.set(project)
  if (project?.timelines[0]?.layers) {
    activeLayer.set(
      project.timelines[0].layers.filter((item) => item.active)[0]
    )
  } else {
    activeLayer.update((a) => null)
    selectedLayers.update((a) => [])
  }
  //   showProjectSidebar.set(false);
}

export const deleteProject = async (projectId) => {
  try {
    await axios.delete(`${backendUrl}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    projects.update((current) =>
      current.filter((project) => project.id !== projectId)
    )
  } catch (error) {
    console.error(error)
  }
}

export const fetchProjectMany = async () => {
  try {
    const response = await axios.get(`${backendUrl}/v03/projects`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    projects.set(response.data)
    if (response.data.length > 0) {
      activeProject.set(response.data[0])
    }
  } catch (error) {
    console.error(error)
  }
}

export const fetchProjectOne = async (projectId) => {
  try {
    const response = await axios.get(`${backendUrl}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    activeProject.set(response.data)
  } catch (error) {
    console.error(error)
  }
}

export async function updateProject(projectId) {
  try {
    const response = await axios.put(
      `${backendUrl}/projects/${projectId}`,
      {
        name: $activeProject.name,
        description: $activeProject.description,
        settings: $activeProject.settings,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    projects.update((current) =>
      current.map((project) =>
        project.id === projectId ? response.data : project
      )
    )
    console.log('response.data', response.data)
    activeProject.set(response.data)
  } catch (error) {
    console.error(error)
  }
}

export async function createProject(newProjectName, newProjectDescription) {
  try {
    const response = await axios.post(
      `${backendUrl}/projects`,
      {
        name: newProjectName,
        description: newProjectDescription,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    projects.update((current) => [...current, response.data])
    console.log('response.data', response.data)
    showCreateModal.set(false)
    activeProject.set(response.data)
  } catch (error) {
    console.error(error)
  }
}

export async function importProject(importProjectFolder) {
  if (get(importProjectModalIsLoading)) {
    return
  }
  try {
    importProjectModalIsLoading.set(true)
    const response = await axios.post(
      `${backendUrl}/projects/import`,
      {
        import_folder: importProjectFolder,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    importProjectModalIsLoading.set(false)
    projects.update((current) => [...current, response.data])
    console.log('response.data', response.data)
    showImportProjectModal.set(false)
    activeProject.set(response.data)
  } catch (error) {
    importProjectModalIsLoading.set(false)
    showImportProjectModal.set(false)
    console.error(error)
  }
}
