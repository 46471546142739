export const controlnetModelInputLabels = {
  enabled: 'Enabled',
  name: 'Name',
  weight: 'Weight',
  // active_range:"Active Range",
  start: 'Start',
  end: 'End',
  mode: 'Mode',
  source: 'Source',
  resolution: 'Resolution',
  preprocess: 'Preprocess',
}

export const controlnetModelInputTypes = {
  enabled: 'checkbox',
  name: 'label',
  weight: 'float',
  // active_range:{
  //   type:'float_range',
  //   min:0,
  //   max:1
  // },
  start: 'float',
  end: 'float',
  mode: {
    type: 'dropdown',
    values: ['global', 'controlnet', 'balanced', 'prompt'],
  },
  source: 'text',
  resolution: 'int',
  preprocess: {
    type: 'dropdown',
    values: ['global', 'True', 'False'],
  },
}

export const settingLabels = {
  vae_path: 'VAE path',
  frame_range: 'Frame range',
  video_init_path: 'Video path:',
  text_prompts: 'Prompt:',
  negative_prompts: 'Negative prompt:',
  cfg_scale_schedule: 'Prompt strength:',
  steps_schedule: 'Quality:',
  style_strength_schedule: 'Style strength:',
  maxsize: 'Max. size:',
  model_path: 'Checkpoint path:',
  model_version: 'Model version:',
  clip_skip: 'Clip Skip:',
  cc_masked_diffusion_schedule: 'Mask diffusion :',
  sampler: 'Sampler:',
  use_karras_noise: 'Karras noise:',

  //external cond
  latent_scale_schedule: 'latent scale:',
  init_scale_schedule: 'init scale:',
  clamp_max: 'clamp max:',

  //seed
  seed: 'Seed:',
  fixed_seed: 'Fixed seed:',
  noise_mode: 'Noise mode:',
  code_randomness: 'Code Randomness:',

  //rec noise
  rec_prompts: 'Prompt',
  rec_cfg: 'Prompt strength',
  rec_randomness: 'Randomness',
  rec_source: 'rec source',
  rec_steps_pct: '% of total steps',
  disable_rec_noise_controlnets: 'disable rec noise controlnets',
  rec_separate_uc: 'rec separate uc',
  rec_steps_cutoff: 'rec steps cutoff',
  rec_noise_scale: 'rec noise scale',
  overwrite_rec_noise: 'overwrite rec noise',

  //warp
  flow_warp: 'Enable optical flow:',
  flow_blend_schedule: 'Flow blend',
  force_flow_generation: 'force flow generation',

  //consistency
  check_consistency: 'Check consistency',
  missed_consistency_schedule: 'Missed',
  overshoot_consistency_schedule: 'Overshoot',
  edges_consistency_schedule: 'Edges',
  consistency_blur_schedule: 'Blur',
  consistency_dilate_schedule: 'Dilate',
  soften_consistency_schedule: 'Soften',
  match_color_strength: 'Match color strength',
  mask_result: 'Mask result',

  //mask
  use_background_mask: 'use_background_mask',
  invert_mask: 'invert_mask',
  background: 'background',
  background_source: 'background_source',
  apply_mask_after_warp: 'apply_mask_after_warp',
  extract_background_mask: 'extract_background_mask',
  force_mask_overwrite: 'force_mask_overwrite',
  mask_source: 'mask_source',

  //colormatch
  colormatch_frame: 'Frame type',
  color_match_frame_str: 'Strength',
  colormatch_offset: 'Offset',
  colormatch_method: 'Method',
  colormatch_after: 'colormatch_after',
  color_video_path: 'color_video_path',
  color_extract_nth_frame: 'color_extract_nth_frame',

  //misc
  save_img_format: 'Image output format',
  clear_cache: 'Clear cache',
  offload_model: 'Offload model',
  use_tiled_vae: 'Use tiled vae',
  num_tiles: 'Number of tiles',
  do_freeunet: 'do_freeunet',
  b1: 'b1',
  b2: 'b2',
  s1: 's1',
  s2: 's2',

  //controlnets
  controlnet_multimodel: '',
  controlnet_preprocess: 'controlnet_preprocess',
  detect_resolution: 'Resolution',
  controlnet_mode: 'Mode',
  normalize_cn_weights: 'normalize_cn_weights',
  save_controlnet_annotations: 'Show on the timeline',
  control_sd15_depth_detector: 'control_sd15_depth_detector',
  pose_detector: 'pose_detector',
  fill_lips: 'fill_lips',
}

export const settingTypes = {
  vae_path: 'hidden',
  model_version: {
    type: 'dropdown',
    values: [
      // 'control_multi_animatediff',
      'control_multi_sdxl',
      // 'control_multi',
      // 'control_multi_animatediff_sdxl',
    ],
  },
  text_prompts: 'textarea', //main
  negative_prompts: 'textarea',
  cfg_scale_schedule: 'text',
  steps_schedule: 'text',
  style_strength_schedule: 'text',
  // style_strength_schedule:{
  //   type:'float_range',
  //   min:0,
  //   max:1
  // },
  maxsize: 'text',
  model_path: 'hidden',
  video_init_path: 'hidden',
  render_output: 'hidden',
  output_dir: 'hidden',
  clip_skip: {
    type: 'int_range',
    min: 0,
    max: 10,
  },
  cc_masked_diffusion_schedule: 'text',
  sampler: {
    type: 'dropdown',
    values: [
      'sample_euler',
      // 'sample_euler_ancestral',
      // 'sample_heun',
      // 'sample_dpm_2',
      // 'sample_dpm_2_ancestral',
      // 'sample_lms',
      // 'sample_dpm_fast',
      // 'sample_dpm_adaptive',
      // 'sample_dpmpp_2s_ancestral',
      'sample_dpmpp_sde',
      // 'sample_dpmpp_2m',
      // 'sample_lcm',
    ],
  },
  use_karras_noise: 'checkbox',

  //external cond
  latent_scale_schedule: 'text',
  init_scale_schedule: 'text',
  clamp_max: 'float',

  //seed
  seed: 'int',
  fixed_seed: 'checkbox',
  noise_mode: {
    type: 'dropdown',
    values: ['fixed', 'reconstructed', 'random'],
  },
  code_randomness: {
    type: 'float_range',
    min: 0,
    max: 1,
  },

  //rec noise
  rec_prompts: 'text',
  rec_cfg: 'float',
  rec_randomness: {
    type: 'float_range',
    min: 0,
    max: 1,
  },
  rec_source: {
    type: 'dropdown',
    values: ['init', 'stylized'],
  },
  rec_steps_pct: {
    type: 'float_range',
    min: 0,
    max: 2,
  },
  disable_rec_noise_controlnets: 'checkbox',
  rec_separate_uc: 'checkbox',
  rec_steps_cutoff: 'int',
  rec_noise_scale: {
    type: 'float_range',
    min: 0,
    max: 1,
  },
  overwrite_rec_noise: 'checkbox',

  //warp
  flow_warp: 'checkbox',
  flow_blend_schedule: 'text',
  // flow_blend_schedule: {
  //     type:'float_range',
  //     min:0,
  //     max:1
  //   },
  force_flow_generation: 'checkbox',

  //consistency
  check_consistency: 'checkbox',
  missed_consistency_schedule: 'text',
  overshoot_consistency_schedule: 'text',
  edges_consistency_schedule: 'text',
  consistency_blur_schedule: 'text',
  consistency_dilate_schedule: 'text',
  soften_consistency_schedule: 'text',

  match_color_strength: {
    type: 'float_range',
    min: 0,
    max: 1,
  },
  mask_result: 'checkbox',

  //mask
  use_background_mask: 'checkbox',
  invert_mask: 'checkbox',
  background: {
    type: 'dropdown',
    values: ['image', 'color', 'init_video'],
  },
  background_source: 'text',
  apply_mask_after_warp: 'checkbox',
  extract_background_mask: 'checkbox',
  force_mask_overwrite: 'checkbox',
  mask_source: 'text',

  //colormatch
  colormatch_frame: {
    type: 'dropdown',
    values: [
      'off',
      'stylized_frame',
      'color_video',
      'color_video_offset',
      'init_frame',
      'stylized_frame_offset',
      'init_frame_offset',
    ],
  },
  color_match_frame_str: {
    type: 'float_range',
    min: 0,
    max: 1,
  },
  colormatch_offset: 'int',
  colormatch_method: {
    type: 'dropdown',
    values: ['LAB', 'PDF', 'mean'],
  },
  colormatch_after: 'checkbox',
  color_video_path: 'text',
  color_extract_nth_frame: 'int',

  //misc
  save_img_format: {
    type: 'dropdown',
    values: ['png', 'jpg'],
  },
  clear_cache: 'checkbox',
  offload_model: 'checkbox',
  use_tiled_vae: 'checkbox',
  num_tiles: 'text',
  do_freeunet: 'checkbox',
  b1: 'float',
  b2: 'float',
  s1: 'float',
  s2: 'float',

  //controlnets
  controlnet_multimodel: 'contolnet_multimodel',
  controlnet_preprocess: 'checkbox',
  detect_resolution: 'int',
  controlnet_mode: {
    type: 'dropdown',
    values: ['balanced', 'controlnet', 'prompt'],
  },
  normalize_cn_weights: 'checkbox',
  save_controlnet_annotations: 'checkbox',
  control_sd15_depth_detector: {
    type: 'dropdown',
    values: ['Zoe', 'Midas', 'depth_anything'],
  },
  pose_detector: {
    type: 'dropdown',
    values: ['openpose', 'dw_pose'],
  },
  fill_lips: 'int',
}

export const diffusion_labels = [
  'clip_skip',
  'cc_masked_diffusion_schedule',
  'sampler',
  'use_karras_noise',

  //external cond
  // 'latent_scale_schedule',
  // 'init_scale_schedule',
  // 'clamp_max',

  //seed
  'seed',
  'fixed_seed',
  'noise_mode',
  'code_randomness',

  //rec noise
  'rec_prompts',
  'rec_cfg',
  'rec_randomness',
  // 'rec_source',
  'rec_steps_pct',
  // 'disable_rec_noise_controlnets',
  // 'rec_separate_uc',
  // 'rec_steps_cutoff',
  'rec_noise_scale',
  // 'overwrite_rec_noise',
]

export const controlnet_labels = [
  'controlnet_multimodel',
  // 'controlnet_preprocess',
  'detect_resolution',
  'controlnet_mode',
  // 'normalize_cn_weights',
  'save_controlnet_annotations',
  // 'control_sd15_depth_detector',
  // 'pose_detector',
  // 'fill_lips',
]

export const warp_consistency_labels = [
  'flow_warp',
  'flow_blend_schedule',
  // 'force_flow_generation',
  'check_consistency',
  'missed_consistency_schedule',
  'overshoot_consistency_schedule',
  'edges_consistency_schedule',
  'consistency_blur_schedule',
  'consistency_dilate_schedule',
  'soften_consistency_schedule',
  'match_color_strength',
  'mask_result',
]

export const mask_labels = [
  'use_background_mask',
  'invert_mask',
  'background',
  'background_source',
  'apply_mask_after_warp',
  'extract_background_mask',
  'force_mask_overwrite',
  'mask_source',
]

export const colormatch_labels = [
  'colormatch_frame',
  'colormatch_offset',
  'colormatch_method',
  'color_match_frame_str'
  // 'colormatch_after',
  // 'color_video_path',
  // 'color_extract_nth_frame',
]

export const misc_labels = [
  'save_img_format',
  'clear_cache',
  'offload_model',
  'use_tiled_vae',
  'num_tiles',
  // 'do_freeunet',
  // 'b1',
  // 'b2',
  // 's1',
  // 's2',
]

const main_labels = [
  'video_init_path',
  'text_prompts',
  'negative_prompts',
  'cfg_scale_schedule',
  'steps_schedule',
  'style_strength_schedule',
  'maxsize',
  'model_path',
  'model_version',
  'output_dir',
  'vae_path',
]

export const settings_tabs = [
  {
    label: 'main_magov1',
    settings: main_labels,
  },
  {
    label: 'diffusion',
    settings: diffusion_labels,
  },
  {
    label: 'controlnets_magov1',
    settings: controlnet_labels,
  },
  {
    label: 'flow & consistency',
    settings: warp_consistency_labels,
  },
  // {
  //   label: 'mask',
  //   settings: mask_labels,
  // },
  {
    label: 'colormatch',
    settings: colormatch_labels,
  },
  {
    label: 'other',
    settings: misc_labels,
  },
]

let possible_controlnets_adiff = [
  'control_sd15_depth',
  'control_sd15_canny',
  'control_sd15_softedge',
  'control_sd15_mlsd',
  'control_sd15_normalbae',
  'control_sd15_openpose',
  'control_sd15_scribble',
  'control_sd15_seg',
  'control_sd15_face',
  'control_sd15_ip2p',
  'control_sd15_lineart',
  'control_sd15_lineart_anime',
  'control_sd15_shuffle',
  'control_sd15_tile',
  'control_sd15_qr',
  'control_sd15_monster_qr',
  'control_sd15_gif',
  'ipadapter_sd15',
  'ipadapter_sd15_light',
  'ipadapter_sd15_plus',
  'ipadapter_sd15_plus_face',
  'ipadapter_sd15_full_face',
  'ipadapter_sd15_vit_G',
  'ipadapter_sd15_faceid',
  'ipadapter_sd15_faceid_plus',
  'ipadapter_sd15_faceid_plus_v2',
  'control_sd15_depth_anything',
]

let possible_controlnets_adiff_sdxl = [
  // 'control_sdxl_canny',
  // 'control_sdxl_depth',
  'control_sdxl_softedge',
  'control_sdxl_seg',
  // 'control_sdxl_openpose',
  // 'control_sdxl_lora_128_depth',
  // 'control_sdxl_lora_256_depth',
  // 'control_sdxl_lora_128_canny',
  // 'control_sdxl_lora_256_canny',
  // 'control_sdxl_lora_128_softedge',
  // 'control_sdxl_lora_256_softedge',
  'ipadapter_sdxl',
  'ipadapter_sdxl_vit_h',
  'ipadapter_sdxl_plus_vit_h',
  'ipadapter_sdxl_plus_face_vit_h',
  'ipadapter_sdxl_faceid',
  'control_sdxl_xinsir_tile',
  'control_sdxl_xinsir_openpose',
  'control_sdxl_xinsir_scribble',
  'control_sdxl_xinsir_canny',
  'control_sdxl_xinsir_depth',
  'control_sdxl_xinsir_scribble_anime',
]

let possible_controlnets_sdxl = [
  'control_sdxl_temporalnet_v1',
  'control_sdxl_inpaint',
]
possible_controlnets_sdxl.push(...possible_controlnets_adiff_sdxl)
let possible_controlnets = [
  'control_sd15_temporalnet',
  'control_sd15_inpaint_softedge',
  'control_sd15_inpaint',
  'control_sd15_temporal_depth',
]
possible_controlnets.push(...possible_controlnets_adiff)

export const all_possible_controlnets = {
  control_multi: possible_controlnets,
  control_multi_sdxl: possible_controlnets_sdxl,
  control_multi_animatediff: possible_controlnets_adiff,
  control_multi_animatediff_sdxl: possible_controlnets_adiff_sdxl,
}

export const default_settings = {
  vae_path: '',
  text_prompts: 'a beautiful highly detailed cyberpunk mechanical augmented most beautiful (woman) ever, cyberpunk 2077, neon, dystopian, hightech, trending on artstation',
  user_comment: 'v0.30',
  image_prompts: {},
  range_scale: 0,
  sat_scale: 0.0,
  max_frames: null,
  init_image: '',
  clamp_grad: true,
  clamp_max: 2.0,
  seed: 4275770367,
  width: 704,
  height: 1280,
  diffusion_model: 'stable_diffusion',
  diffusion_steps: 1000,
  video_init_path: '',
  extract_nth_frame: 1,
  flow_video_init_path: null,
  flow_extract_nth_frame: 1,
  video_init_seed_continuity: false,
  turbo_mode: false,
  turbo_steps: 3,
  turbo_preroll: 1,
  flow_warp: true,
  check_consistency: true,
  turbo_frame_skips_steps: '100% (don`t diffuse turbo frames, fastest)',
  forward_weights_clip: 0,
  forward_weights_clip_turbo_step: 0,
  padding_ratio: 0.2,
  padding_mode: 'reflect',
  consistency_blur: 1,
  inpaint_blend: 0,
  match_color_strength: 0.0,
  high_brightness_threshold: 180,
  high_brightness_adjust_ratio: 0.97,
  low_brightness_threshold: 40,
  low_brightness_adjust_ratio: 1.03,
  stop_early: 0,
  high_brightness_adjust_fix_amount: 2,
  low_brightness_adjust_fix_amount: 2,
  max_brightness_threshold: 254,
  min_brightness_threshold: 1,
  enable_adjust_brightness: false,
  dynamic_thresh: 30.0,
  warp_interp: 1,
  fixed_code: null,
  code_randomness: 0.1,
  mask_result: false,
  reverse_cc_order: true,
  flow_lq: true,
  use_predicted_noise: null,
  clip_guidance_scale: 0,
  clip_type: 'ViT-H-14',
  clip_pretrain: 'laion2b_s32b_b79k',
  missed_consistency_weight: 1,
  overshoot_consistency_weight: 1,
  edges_consistency_weight: 1,
  style_strength_schedule: 1,
  flow_blend_schedule: 1,
  steps_schedule: 20,
  init_scale_schedule: 0,
  latent_scale_schedule: 0,
  latent_scale_template: '',
  init_scale_template: '',
  steps_template: '',
  style_strength_template: '',
  flow_blend_template: [0.8, 0.0, 0.51, 2],
  cc_masked_template: [0.7, 0, 0.51, 2],
  make_schedules: false,
  normalize_latent: 'off',
  normalize_latent_offset: 0,
  colormatch_frame: 'stylized_frame',
  use_karras_noise: false,
  end_karras_ramp_early: false,
  use_background_mask: false,
  apply_mask_after_warp: true,
  background: 'init_video',
  background_source: 'red',
  mask_source: 'init_video',
  extract_background_mask: false,
  force_mask_overwrite: false,
  negative_prompts: 'text, naked, nude, logo, cropped, two heads, four arms, lazy eye, blurry, unfocused',
  invert_mask: false,
  warp_strength: 1.0,
  flow_override_map: [],
  cfg_scale_schedule: 7,
  respect_sched: true,
  color_match_frame_str: 0.5,
  colormatch_offset: 0,
  latent_fixed_mean: 0.0,
  latent_fixed_std: 0.9,
  colormatch_method: 'PDF',
  colormatch_regrain: null,
  warp_mode: 'use_image',
  use_patchmatch_inpaiting: 0.0,
  blend_latent_to_init: 0.0,
  warp_towards_init: 'off',
  init_grad: false,
  grad_denoised: true,
  colormatch_after: true,
  colormatch_turbo: false,
  model_version: 'control_multi_sdxl',
  cond_image_src: 'init',
  warp_num_k: 128,
  warp_forward: false,
  sampler: 'sample_euler',
  mask_clip: [0, 255],
  inpainting_mask_weight: 1.0,
  inverse_inpainting_mask: false,
  model_path: '',
  diff_override: [],
  image_scale_schedule: 1.5,
  image_scale_template: null,
  frame_range: [0, 0],
  detect_resolution: 1280,
  bg_threshold: 0.4,
  diffuse_inpaint_mask_blur: 25,
  diffuse_inpaint_mask_thresh: 0.8,
  add_noise_to_latent: true,
  noise_upscale_ratio: 1,
  fixed_seed: false,
  init_latent_fn: 'spherical_dist_loss',
  value_threshold: 0.1,
  distance_threshold: 0.1,
  masked_guidance: false,
  cc_masked_diffusion_schedule: 0.7,
  alpha_masked_diffusion: 0.0,
  inverse_mask_order: false,
  invert_alpha_masked_diffusion: false,
  quantize: true,
  cb_noise_upscale_ratio: 1,
  cb_add_noise_to_latent: true,
  cb_use_start_code: true,
  cb_fixed_code: false,
  cb_norm_latent: false,
  guidance_use_start_code: true,
  save_img_format: 'png',
  clear_cache: false,
  offload_model: true,
  controlnet_preprocess: true,
  small_controlnet_model_path: '',
  use_scale: false,
  g_invert_mask: false,
  controlnet_multimodel: {
    control_sdxl_inpaint: {
      enabled: true,
      weight: 0.25,
      start: 0.0,
      end: 1,
      preprocess: 'global',
      mode: 'global',
      detect_resolution: -1,
      source: 'stylized',
    },

    control_sdxl_xinsir_tile: {
      enabled: true,
      weight: 0.2,
      start: 0.0,
      end: 1.0,
      preprocess: 'global',
      mode: 'global',
      detect_resolution: -1,
      source: 'global',
    },
    control_sd15_softedge: {
      enabled: true,
      weight: 0.2,
      start: 0.0,
      end: 1,
      preprocess: 'global',
      mode: 'global',
      detect_resolution: -1,
      source: 'global',
    },
    control_sdxl_xinsir_depth: {
      enabled: true,
      weight: 0.5,
      start: 0.0,
      end: 1,
      preprocess: 'global',
      mode: 'global',
      detect_resolution: -1,
      source: 'global',
    },
  },
  img_zero_uncond: false,
  do_softcap: false,
  softcap_thresh: 0.9,
  softcap_q: 1.0,
  deflicker_latent_scale: 0.0,
  deflicker_scale: 0.0,
  controlnet_multimodel_mode: 'internal',
  no_half_vae: false,
  temporalnet_source: 'stylized',
  temporalnet_skip_1st_frame: true,
  rec_randomness: 0.0,
  rec_source: 'init',
  rec_cfg: 1.0,
  rec_prompts:'a photo',
  inpainting_mask_source: 'none',
  rec_steps_pct: 1.0,
  max_faces: 10,
  num_flow_updates: 20,
  pose_detector: 'dw_pose',
  control_sd15_openpose_hands_face: true,
  control_sd15_depth_detector: 'depth_anything',
  control_sd15_softedge_detector: 'PIDI',
  control_sd15_seg_detector: 'Seg_UFADE20K',
  control_sd15_scribble_detector: 'PIDI',
  control_sd15_lineart_coarse: false,
  control_sd15_inpaint_mask_source: 'consistency_mask',
  control_sd15_shuffle_source: 'color_video',
  control_sd15_shuffle_1st_source: 'color_video',
  overwrite_rec_noise: true,
  use_legacy_cc: false,
  missed_consistency_dilation: 2,
  edge_consistency_width: 11,
  use_reference: false,
  reference_weight: 0.5,
  reference_source: 'init',
  reference_mode: 'Balanced',
  use_legacy_fixed_code: false,
  consistency_dilate: 3,
  prompt_patterns_sched: {},
  sd_batch_size: 2,
  normalize_prompt_weights: true,
  controlnet_low_vram: false,
  mask_paths: [],
  controlnet_mode: 'controlnet',
  normalize_cn_weights: false,
  apply_freeu_after_control: false,
  do_freeunet: false,
  batch_length: 32,
  batch_overlap: 8,
  looped_noise: true,
  overlap_stylized: true,
  context_length: 16,
  context_overlap: 10,
  blend_batch_outputs: true,
  force_flow_generation: true,
  flow_threads: 4,
  num_flow_workers: 0,
  flow_save_img_preview: false,
  lazy_warp: true,
  clip_skip: 1,
  qr_cn_mask_clip_high: 255,
  qr_cn_mask_clip_low: 0,
  qr_cn_mask_invert: false,
  qr_cn_mask_grayscale: false,
  use_manual_splits: false,
  scene_split_thresh: 0.5,
  scene_splits: null,
  blend_prompts_b4_diffusion: true,
  fill_lips: 20,
  flow_maxsize: 0,
  missed_consistency_schedule: 1,
  overshoot_consistency_schedule: 1,
  edges_consistency_schedule: 1,
  consistency_blur_schedule: 0,
  consistency_dilate_schedule: 2,
  soften_consistency_schedule: 0,
  use_tiled_vae: true,
  num_tiles: [2, 2],
  color_video_path: '',
  color_extract_nth_frame: 1,
  b1: 1.2,
  b2: 1.4,
  s1: 0.9,
  s2: 0.2,
  noise_mode: 'reconstructed',
  disable_rec_noise_controlnets: true,
  batched_adiff_rec_noise: true,
  rec_separate_uc: true,
  rec_neg_prompts: '',
  rec_noise_scale: 1.0,
  rec_steps_cutoff: 0,
  settings_filename: '',
  maxsize: 1280,
  render_output: '',
  output_dir: '',
  skip_video_for_run_all: false,
  save_controlnet_annotations: false,
}
