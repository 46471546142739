import { removeNullKeys, deStringifyOnSave } from '../../SettingsUI/utils'

export function compareJsonObjects(json1, json2) {
  let changes = []
  const removed = []
  const added = []
  if (!json1 || !json2) {
    return {
      changes,
      removed,
      added,
    }
  }
  json1 = deStringifyOnSave(removeNullKeys(json1))
  json2 = deStringifyOnSave(removeNullKeys(json2))

  const jointKeys = new Set([...Object.keys(json1), ...Object.keys(json2)])

  jointKeys.forEach((key) => {
    if (key !== 'controlnet_multimodel') {
      const json1Value = JSON.stringify(json1[key])
      const json2Value = JSON.stringify(json2[key])
      if (key in json1 && key in json2 && json1Value !== json2Value) {
        changes.push({
          name: key,
          left: json1Value,
          right: json2Value,
        })
      }
      if (key in json1 && !(key in json2)) {
        removed.push({
          name: key,
          left: json1Value,
          right: null,
        })
      }
      if (!(key in json1) && key in json2) {
        added.push({
          name: key,
          left: null,
          right: json2Value,
        })
      }
    }
  })

  if (json1['controlnet_multimodel'] !== json2['controlnet_multimodel']) {
    const f1Controlnet = deStringifyOnSave(
      removeNullKeys(json1['controlnet_multimodel'])
    )
    const f2Controlnet = deStringifyOnSave(
      removeNullKeys(json2['controlnet_multimodel'])
    )
    if (json1['model_version'] == json2['model_version']) {
      let cnChanges = compareJsonObjects(f1Controlnet, f2Controlnet).changes

      let newChange = []
      cnChanges.forEach((change) => {
        let json1 = JSON.parse(change['left'])
        let json2 = JSON.parse(change['right'])

        let newleft = []
        let newright = []
        const jointKeys2 = new Set([
          ...Object.keys(json1),
          ...Object.keys(json2),
        ])
        console.log('json1', json1)
        console.log('jointKeys2', jointKeys2)
        jointKeys2.forEach((key) => {
          const json1Value = JSON.stringify(json1[key])
          const json2Value = JSON.stringify(json2[key])
          console.log('key', key)
          if (key in json1 && key in json2 && json1Value !== json2Value) {
            newleft.push({
              [key]: json1Value,
            })
            newright.push({
              [key]: json2Value,
            })
          }
        })
        newChange.push({
          name: change.name,
          left: newleft
            .map((jsonObj) => {
              const key = Object.keys(jsonObj)[0]
              const value = jsonObj[key]
              return `${key}: ${value}`
            })
            .join('\n'),
          right: newright
            .map((jsonObj) => {
              const key = Object.keys(jsonObj)[0]
              const value = jsonObj[key]
              return `${key}: ${value}`
            })
            .join('\n'),
        })
      })

      changes = [...changes, ...newChange]
    } else {
      changes.push({
        name: 'controlnet_multimodel',
        left: f1Controlnet,
        right: f2Controlnet,
      })
    }
  }

  return {
    changes,
    removed,
    added,
  }
}
