<script>
  import { onMount, onDestroy } from 'svelte'
  import { writable } from 'svelte/store'
  import ThreeDots from './icons/ThreeDots.svelte'
  export let onDuplicate = null
  export let onDownloadVideo = null
  export let onDownloadFrames = null
  // export let onLike = () => {};
  // export let onDislike = () => {};
  export let onDelete = null
  export let layer
  import { openModalId } from './stores'
  import { updateLayer } from './v02/services/layerService'

  let buttonCoords = { top: 0, left: 0 }

  export let id = 0

  function moveLayer(offset, activeTimeline) {
    console.log('moving', activeTimeline)
    if (!activeTimeline?.layers) {
      return
    }

    let layers = activeTimeline?.layers

    const layerToRemove = layers.find((l) => l.id === layer.id)

    if (layerToRemove) {
      const index = layers.indexOf(layerToRemove) // Find the index of the layer
      if (index > -1) {
        layers.splice(index, 1) // Remove the layer at that index
      }
    }

    layers.splice(layer.layer_order + offset - 1, 0, layer)
    layers.forEach((l, index) => {
      l.layer_order = index + 1
    })

    activeTimeline.layers = layers
    updateLayer(layerToRemove)
  }

  function toggleModal(event) {
    const rect = event.target.getBoundingClientRect()
    buttonCoords = {
      top: rect.top,
      left: rect.left,
    }
    document.body.classList.add('no-scroll') // Disable scrolling

    openModalId.set(id) // Set the current modal as open
  }

  function closeModal() {
    document.body.classList.remove('no-scroll') // Enable scrolling

    openModalId.set(null) // Close the modal
  }

  const closeAnyOpenMenu = () => {
    const existingMenu = document.querySelector('.layer-context')
    if (existingMenu) {
      openModalId.set(null) // Reset the openMenuId to close any open menu
    }
  }

  const handleClickOutside = (event) => {
    if (!event.target.closest('.layer-context')) {
      closeAnyOpenMenu()
    }
  }

  onMount(() => {
    document.addEventListener('click', handleClickOutside)
  })

  onDestroy(() => {
    document.removeEventListener('click', handleClickOutside)
  })
</script>

<main>
  <!-- Button to open the modal -->
  <span class="button onhover" on:click={toggleModal}><ThreeDots /></span>

  <!-- Modal window -->
  {#if $openModalId === id}
    <div>
      <div
        class="modal-bg open"
        style=" z-index:998;"
        on:click={closeModal}
        on:keypress={closeModal}
      ></div>
      <div
        class="layer-context"
        on:click|stopPropagation
        on:keypress={() => {}}
        style="top: {Math.min(
          buttonCoords.top,
          window.innerHeight - 160
        )}px; left: {buttonCoords.left - 220}px; z-index:999;"
      >
        <ul>
          {#if onDuplicate}
            <li
              on:keypress={() => {}}
              on:click={() => {
                onDuplicate()
                closeModal()
              }}
            >
              Duplicate
            </li>
          {/if}
          {#if onDownloadVideo}
            <li
              on:keypress={() => {}}
              on:click={() => {
                onDownloadVideo()
                closeModal()
              }}
            >
              Download video
            </li>
          {/if}
          {#if onDownloadFrames}
            <li
              on:keypress={() => {}}
              on:click={() => {
                onDownloadFrames()
                closeModal()
              }}
            >
              Download frames
            </li>
          {/if}
          <!-- <li on:click={() => { onLike(); closeModal(); }}>Like</li> -->
          <!-- <li on:click={() => { onDislike(); closeModal(); }}>Dislike</li> -->
          {#if onDelete}
            <li
              on:keypress={() => {}}
              on:click={() => {
                onDelete()
                closeModal()
              }}
            >
              Delete layer
            </li>
          {/if}
        </ul>
      </div>
    </div>
  {/if}
</main>

<style>
  .button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 0;
    z-index: 26;
  }

  .modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.01);
    display: none;
    z-index: 998;
  }

  .modal-bg.open {
    display: block;
  }

  .layer-context {
    position: fixed;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    min-width: 170px;
  }

  .layer-context ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .layer-context li {
    padding: 8px 12px;
    cursor: pointer;
  }

  .layer-context li:hover {
    background-color: #444;
  }

  .onhover:hover {
    text-decoration: underline;
    cursor: pointer;
    & > svg > path {
      fill: var(--text-hover);
      transition: 200ms;
    }
  }
</style>
