import axios from 'axios'
import { backendUrl } from '../stores/urls'

export const selectLayersMany = async (layerIds) => {
  if (!layerIds) {
    return
  }
  try {
    console.log('layerIds', layerIds)
    const response = await axios.post(
      `${backendUrl}/preview`,
      {
        layer_ids: layerIds,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    console.log('selectLayersMany response', response.data)
  } catch (error) {
    console.error(error)
  }
}

export async function fetchImageWithToken(url) {
  try {
    const response = await fetch(`${backendUrl}/${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    if (!response.ok) {
      throw new Error('Failed to fetch image')
    }

    const blob = await response.blob()
    const objectUrl = URL.createObjectURL(blob)
    return objectUrl
  } catch (error) {
    console.error('Error fetching image:', error)
  }
}
