<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.81095 20L9.49254 17.44C9.32007 17.3733 9.15755 17.2933 9.00498 17.2C8.8524 17.1067 8.70315 17.0067 8.55721 16.9L6.18905 17.9L4 14.1L6.04975 12.54C6.03648 12.4467 6.02985 12.3567 6.02985 12.27V11.73C6.02985 11.6433 6.03648 11.5533 6.04975 11.46L4 9.9L6.18905 6.1L8.55721 7.1C8.70315 6.99333 8.85572 6.89333 9.01493 6.8C9.17413 6.70667 9.33333 6.62667 9.49254 6.56L9.81095 4H14.1891L14.5075 6.56C14.6799 6.62667 14.8425 6.70667 14.995 6.8C15.1476 6.89333 15.2968 6.99333 15.4428 7.1L17.8109 6.1L20 9.9L17.9502 11.46C17.9635 11.5533 17.9701 11.6433 17.9701 11.73V12.27C17.9701 12.3567 17.9569 12.4467 17.9303 12.54L19.9801 14.1L17.791 17.9L15.4428 16.9C15.2968 17.0067 15.1443 17.1067 14.9851 17.2C14.8259 17.2933 14.6667 17.3733 14.5075 17.44L14.1891 20H9.81095ZM12.0398 14.8C12.8093 14.8 13.466 14.5267 14.01 13.98C14.5539 13.4333 14.8259 12.7733 14.8259 12C14.8259 11.2267 14.5539 10.5667 14.01 10.02C13.466 9.47333 12.8093 9.2 12.0398 9.2C11.257 9.2 10.597 9.47333 10.0597 10.02C9.52239 10.5667 9.25373 11.2267 9.25373 12C9.25373 12.7733 9.52239 13.4333 10.0597 13.98C10.597 14.5267 11.257 14.8 12.0398 14.8Z"
    fill="#8F8F8F"
  />
</svg>
