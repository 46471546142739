<script>
  import { backendUrl, queue_length, queue } from '../../stores.js'
  import { queue_refresh_delay } from '../../constants.js'
  import Modal from './Modal.svelte'
  import QueueItem from './QueueItem.svelte'
    import { onMount } from 'svelte'
    import { activeProject } from '../stores/project.js'

  let isOpen = false

  function sortQueueByStatus(queue) {
    const error = queue.filter((q) => q.status === 'ERROR')
    const done = queue.filter((q) => q.status === 'FINISHED')
    const inprogress = queue
      .filter((q) => q.status === 'INPROGRESS')
      .sort((a, b) => b.progress - a.progress)
    const pending = queue.filter((q) => q.status === 'PENDING')
    let new_queue = [...error, ...done, ...inprogress, ...pending]
    return new_queue
  }

  async function getQueue() {
    const response = await fetch(`${backendUrl}/queue`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    let new_queue = await response.json()
    new_queue = sortQueueByStatus(new_queue)
    $queue = new_queue
    $queue_length = Object.keys($queue).length
  }

  onMount(()=>{
    getQueue();
    setInterval(getQueue, queue_refresh_delay)
  })

  $: $activeProject, getQueue()
  

  const close = (save = true) => {
    isOpen = false
  }
</script>

<Modal
  onOpen={async () => {
    await getQueue()
  }}
  onClose={() => close(true)}
  buttonStyle="border: none; margin-bottom: 0;"
  cancelButton={false}
>
  <div slot="button" class="trigger-button">
    Render Queue
    <div class="render-queue-count">
      {$queue_length > -1 ? $queue_length : ''}
    </div>
  </div>
  <div slot="main">
    Render Queue
    <div
      class="vert-container"
      style="width: 100%; padding: 0; padding-top: 50px;"
    >
 
      {#each $queue  as q}
      {#if q.show_done || q.status != 'FINISHED'}
        <QueueItem queueItem={q} />
        {/if}
      {/each}
    </div>
  </div>
  <slot slot="closeButton">Close render queue</slot>
</Modal>

<style>
  .trigger-button {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .vert-container {
    display: flex;
    justify-content: start;
    flex-direction: column;
 
    padding: 10px;
    overflow: auto;
    overflow-x: hidden;
    height: 70vh;
  }

  .horiz-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    justify-content: center;
  }
  .setting-input {
    margin-bottom: 5px;
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
    padding: 8px;
    width: 100%;
  }

  .render-queue-count {
    color: #baae3e;
    &:after {
      content: '●';
      margin-left: 3px;
    }
  }

  .delete-btn {
    margin-left: 10px;
    color: white;
    padding: 10px 10px;
  }
</style>
