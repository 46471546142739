<script>
  export let progress = 0
  export let style = "width: 100%; height: 100%;";
  export let animateGray = true
  //   export progressType = 'green'
</script>

<div style={style} class='horiz-container'>
  <div class="progress-bar" style="width: {progress}%">
    <!-- <div class="progress-gray" style="width: {100-progress}%"></div> -->
  </div>
  <div class="{animateGray? "progress-gray": "gray"}" style="width: {100 - progress}%">
    <!-- <div class="progress-gray" style="width: {100-progress}%"></div> -->
  </div>
</div>

<style>
  .progress-bar {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    background:
      linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.15) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.15) 50%,
          rgba(255, 255, 255, 0.15) 75%,
          transparent 75%
        )
        left/67px 67px repeat-x,
      linear-gradient(
          to right,
          rgb(1, 167, 1) 0%,
          rgb(0, 131, 0) 50%,
          rgb(0, 64, 0) 100%
        )
        left/var(--p, 100%) fixed,
      lightgray;
    box-shadow: inset 0px -2px 5px rgba(0, 0, 0, 0.5);
    animation: change 1s linear infinite;
  }

  .gray {
    height: 100%;
    border-radius: 0 8px 8px 0;
    background-color:  rgb(20, 20, 20) 100%;
  }

  .progress-gray {
    height: 100%;
    border-radius: 8px;
    background:
      linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.05) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.05) 50%,
          rgba(255, 255, 255, 0.05) 75%,
          transparent 75%
        )
        left/67px 67px repeat-x,
      linear-gradient(
          to right,
          rgb(10, 10, 10) 0%,
          rgb(15, 15, 15) 50%,
          rgb(20, 20, 20) 100%
        )
        left/var(--p, 100%) fixed,
      lightgray;
    box-shadow: inset 0px -2px 5px rgba(0, 0, 0, 0.5);
    animation: change 1s linear infinite;
  }
  @keyframes change {
    from {
      background-position:
        0 0,
        left;
    }
    to {
      background-position:
        67px 0,
        left;
    }
  }
</style>
