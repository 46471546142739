import axios from 'axios'
import { backendUrl } from '../stores/urls'

export const deleteClip = async (clips, clip) => {
  try {
    await axios.delete(`${backendUrl}/clips/${clip.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    clips = clips.filter((c) => c.id !== clip.id)
  } catch (error) {
    console.error(error)
  }
  return clips
}

export const updateClip = async (clip) => {
  try {
    clip = await axios.put(
      `${backendUrl}/clips/${clip.id}`,
      {
        layer_id: clip.layer_id,
        name: clip.name,
        data: clip.data,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
  } catch (error) {
    console.error(error)
  }
  return clip.data
}

export async function downloadZip(url, filename) {
  const token = localStorage.getItem('token'); // Replace with your actual JWT

  try {
      const response = await axios({
          url: `${backendUrl}${url}`,
          method: 'GET',
          responseType: 'blob', // Important
          headers: {
              'Authorization': `Bearer ${token}`, // Adding the JWT token in the Authorization header
          },
      });

      if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename; // Name for the downloaded file
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      } else {
          throw new Error('Network response was not ok.');
      }
  } catch (error) {
      console.error('There has been a problem with your axios operation:', error);
  }
}

