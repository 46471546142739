<script>
  export let input_type = 'text'
  export let value = null
  export let saveSettings = null
  export let label = ''
  export let style = ''
  export let onChange
  import Textarea from './Textarea.svelte'
  import ControlnetUi from './ControlnetUI.svelte'
  import { all_possible_controlnets } from '../data/magov1/data.js'
  import { settings } from '../stores.js'
  export let disabled = false
  export let handleFocus
  import ArrowDown from '../icons/ArrowDown.svelte'
</script>

<div class="setting">
  {#if ['text', 'int', 'float'].includes(input_type)}
    {#if label != 'Video path:'}
      <label>
        {label}
        <input
          type="text"
          bind:value
          on:blur={saveSettings}
          on:focus={handleFocus}
          class="setting-input form-control {disabled ? 'disabled':''}"
          {style}
          {disabled}
        />
      </label>
    {:else if input_type != 'hidden'}
      <label>
        {label}
        <input
          type="text"
          value={value?.split('/').pop() || ''}
          disabled="true"
          class="setting-input form-control disabled"
          {style}
        />
      </label>
    {/if}
  {:else if input_type == 'checkbox'}
    <label class="container">
      {label}
      <input
        type="checkbox"
        bind:checked={value}
        on:blur={saveSettings}
        class="setting-input  {disabled ? 'disabled':''}"
        {style}
        {disabled}
        on:focus={handleFocus}
      />
      <span class="checkmark"></span>
    </label>
  {:else if input_type == 'textarea'}
    {label}
    <Textarea
      bind:value
      on:blur={saveSettings}
      {label}
      class="setting-input form-control  {disabled ? 'disabled':''}"
      {style}
      {saveSettings}
      {disabled}
      {handleFocus}
    />
  {:else if typeof input_type === 'object'}
    {#if input_type.type == 'dropdown'}
      <label for={label}>{label}</label>
      <div class="select-wrapper">
        <select
          id={label}
          bind:value
          on:blur={saveSettings}
          class="setting-input form-control select-input  {disabled ? 'disabled':''}"
          {style}
          {disabled}
          on:focus={handleFocus}
          on:change={onChange}
        >
          {#each input_type.values as v}
            <option value={v?.name || v}>{v?.label || v}</option>
          {/each}
        </select>
        <ArrowDown className="arrow-icon" />
      </div>
    {:else if input_type.type == 'float_range'}
      <label class="range-input">
        {label}
        <div>
          {value}
        </div>
        <input
          type="range"
          min={input_type.min}
          max={input_type.max}
          step="0.05"
          bind:value
          on:blur={saveSettings}
          class="setting-input form-control  {disabled ? 'disabled':''}"
          {style}
          {disabled}
          on:focus={handleFocus}
        />
      </label>
    {:else if input_type.type == 'int_range'}
      <label class="range-input">
        {label}
        <div>
          {value}
        </div>
        <input
          type="range"
          min={input_type.min}
          max={input_type.max}
          step="1"
          bind:value
          on:blur={saveSettings}
          class="setting-input form-control  {disabled ? 'disabled':''}"
          {style}
          {disabled}
          on:focus={handleFocus}
        />
      </label>
    {/if}
  {:else if input_type == 'contolnet_multimodel'}
    {typeof all_possible_controlnets[
      $settings.renderSettings[$settings.renderEngine]['model_version']
    ]}
    {#each all_possible_controlnets[$settings.renderSettings[$settings.renderEngine]['model_version']] as controlnet_model}
      <!-- {controlnet_model} ------------------------- -->
      <ControlnetUi
        label={controlnet_model}
        bind:value={value[controlnet_model]}
        {saveSettings}
        {handleFocus}
        {disabled}
      />
    {/each}
  {/if}
</div>

<style>
  .disabled{
    cursor:default;
    color: gray;
    border: none;
    background-color:var(--surface-primary);
  }
  .disabled:hover { 
    
    color: gray;
    border: none;
  }

  .form-control {
    margin-top: 5px;
    margin-bottom: 15px; /* Spacing between form elements */
    width: 100%; /* Full width to ensure alignment */
  }

  .container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 4px;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    background-color: #1a1a1a;
  }

  /* Checkbox hover */
  .container:hover input ~ .checkmark {
    background-color: #141414;
  }

  .container input:checked ~ .checkmark {
    background-color: #141414;
  }

  .checkmark:after,
  .checkmark:before {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after,
  .container input:checked ~ .checkmark:before {
    display: block;
  }

  .container .checkmark:after {
    left: 3px;
    top: 8px;
    height: 2px;
    width: 5px;
    border-radius: 2px;
    background-color: #d1d1c9;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .container .checkmark:before {
    left: 5px;
    top: 7px;
    height: 2px;
    width: 9px;
    border-radius: 2px;
    background-color: #d1d1c9;
    -webkit-transform: rotate(130deg);
    -ms-transform: rotate(45deg);
    transform: rotate(130deg);
  }
  /* sdlkfnslkdfnls;kdfnlkfsd */

  /* Start range input */
  .range-input {
    display: grid;
    text-transform: capitalize;
    align-items: center;
    grid-template-columns: 2fr 0.5fr 1fr;
    gap: 6px;
  }

  .range-input > div {
    padding: 4px;
    background-color: #141414;
    height: 34px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type='range'] {
    padding: 0;
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 42px;
    width: 200px;
    cursor: pointer;
    border-radius: 0; /* iOS */
    border-radius: 4px;
  }

  ::-webkit-slider-runnable-track {
    background: #141414;
    border-radius: 4px;
  }

  input[type='range']:active {
    cursor: grabbing;
  }

  input[type='range']:active {
    cursor: grabbing;
  }

  input[type='range']:active::-webkit-slider-thumb {
    box-shadow: -200px 0 0 200px #4c3095;
  }

  input[type='range']:active::-moz-range-thumb {
    box-shadow: -200px 0 0 200px #4c3095;
  }

  input[type='range']:active::-ms-fill-lower {
    background: #4c3095;
  }

  input[type='range']:active::-ms-thumb {
    background: #4c3095;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px; /* 1 */
    height: 42px;
    background: #8f8f8f;
    border-radius: 4px;
    box-shadow: -200px 0 0 200px #303030; /* 2 */
    border: none; /* 1 */
  }

  ::-moz-range-track {
    height: 36px;
    background: #141414;
    border-radius: 4px;
  }

  ::-moz-range-thumb {
    background: #8f8f8f;
    height: 40px;
    width: 12px; /* 1 */
    border: none;
    border-radius: 0 !important;
    box-shadow: -200px 0 0 200px #303030;
    box-sizing: border-box;
  }

  ::-ms-fill-lower {
    background: #303030;
  }

  ::-ms-thumb {
    background: #8f8f8f;
    border-radius: 4px;
    border: none;
    height: 40px;
    width: 12px; /* 1 */
    box-sizing: border-box;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: #141414;
    border-radius: 4px;
    color: transparent;
    height: 42px;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 8px;
    position: relative;
    padding-right: 20px;
  }
  .select-wrapper {
    position: relative;
  }
</style>
