import { downloadZip } from '../services/clipService'

export async function downloadFrames(layer) {
  let filename = `${layer.id}_frames.zip`
  let url = `/download_frames/layer/${layer.id}`
  downloadZip(url, filename)
}

export async function downloadVideo(layer) {
  let filename = `${layer.id}_videos.mp4`
  let url = `/download_one_video/layer/${layer.id}`
  downloadZip(url, filename)
}

export function hasFrames(layer) {
  if (!layer?.clips.length) {
    return false
  }
  let clips = layer.clips
  for (let i = 0; i < clips.length; i++) {
    if (clips[i]?.asset?.progress > 0) {
      return true
    }
  }
}

export function calcFrames(frameRange) {
  if (frameRange == [0, 0]) {
    return -1
  }
  return frameRange[1] - frameRange[0]
}
