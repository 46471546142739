import axios from 'axios'
import { backendUrl } from '../stores/urls'
import { videoUploadProgress } from '../stores/timeline'

export const uploadFile = async (event, timeline, layer) => {
  const files = event.target.files
  if (files.length === 0) {
    console.log('No file selected!')
    return
  }

  const formData = new FormData()
  formData.append('file', files[0])
  formData.append('timeline_id', timeline?.id)
  formData.append('layer_id', layer?.id)

  try {
    const response = await axios.post(
      `${backendUrl}/assets/upload_video`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        onUploadProgress: (progressEvent) => {
          console.log('progresss', progressEvent)
          if (progressEvent.lengthComputable) {
            videoUploadProgress.set(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        }
      }
    )

    console.log('File uploaded successfully:', response.data) 
    //returns timeline obj if provided a timeline, or layer obj if layer provided, or asset obj if neither provided
    return response.data
  } catch (error) {
    console.error(`HTTP error! status: ${error.response.status}`)
    throw new Error(`HTTP error! status: ${error.response.status}`)
  }
}

export const uploadImage = async (event) => {
  const files = event.target.files
  if (files.length === 0) {
    console.log('No file selected!')
    return
  }

  const formData = new FormData()
  formData.append('file', files[0])

  try {
    const response = await axios.post(
      `${backendUrl}/assets/upload_image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )

    console.log('File uploaded successfully:', response.data) 
    //returns timeline obj if provided a timeline, or layer obj if layer provided, or asset obj if neither provided
    return response.data
  } catch (error) {
    console.error(`HTTP error! status: ${error.response.status}`)
    throw new Error(`HTTP error! status: ${error.response.status}`)
  }
}
