<script>
  import Projects from './Projects.svelte'
  import ProjectsModal from './ProjectsModal.svelte'
  import VideoPreview from './VideoPreview.svelte'
  import Settings from './Settings.svelte'
  import Sidebar from './Sidebar.svelte'
  import { isLoggedIn } from './v02/stores/auth'

  import Login from './v02/routes/Login.svelte'
  import SettingsModal from './v02/components/SettingsModal.svelte'
  import Version from './v02/components/Version.svelte'
  import { writable } from 'svelte/store'
  import RenderQModal from './v02/components/RenderQModal.svelte'
  import TimelineList from './TimelineList.svelte'
  import { backendUrl, settings } from './stores'
  import {
    isOpenSettings,
    isOpenSettingsDiff,
  } from './v02/stores/renderSettings'
  import { updateClip } from './v02/services/clipService'
  import {
    activeLayer,
    activeTimeline,
    settingsDiff,
  } from './v02/stores/timeline'
  import { activeProject } from './v02/stores/project'

  import SettingsDiff from './SettingsDiff.svelte'
  // import TimelineControls from './v02/components/TimelineControls.svelte'
  import Notification from './Notification.svelte'
  import { getInitSettings } from './controllers/settingsController'

  let isOpenProjects = writable(false)

  let isv02 = writable(false)
  function toggleProjects() {
    $isOpenProjects = !$isOpenProjects
    $isOpenSettings = false
    $isOpenSettingsDiff = false
  }
  async function toggleSettings() {
    $isOpenSettings = !$isOpenSettings
    $isOpenProjects = false
    $isOpenSettingsDiff = false
    if ($activeLayer) {
      $settings = $activeLayer?.data?.render_settings || getInitSettings()
      if ($activeLayer.clips.length > 0) {
        $activeLayer.clips[0].data['render_settings'] = $settings
        $activeLayer.clips[0] = await updateClip($activeLayer?.clips[0])
      }
    }
  }

  function toggleSettingsDiff() {
    $isOpenSettingsDiff = !$isOpenSettingsDiff
    $isOpenSettings = false
    $isOpenProjects = false
  }
  
</script>

<main>
  <Notification />
  <div class="vert-container">
    <nav>
      <div class="horiz-container">
        <img src="logo.svg" alt="Mago" class="logo" />
        {#if $isLoggedIn}
        <Sidebar
          side="left"
          position="1"
          sidebarType="projects"
          isOpen={$isOpenProjects}
        >
          <div slot="content">
            <p class="project-title">Projects</p>
            <Projects />
          </div>
          <div slot="name">Projects</div>
        </Sidebar>
        <ProjectsModal />
        <Sidebar
          side="left"
          position="1"
          sidebarType="projects"
          isOpen={$isOpenSettingsDiff}
        >
          <div slot="content">
            <p class="project-title">Diff</p>
            <SettingsDiff />
          </div>
          <div slot="name">Diff</div>
        </Sidebar>
        <div class="sidebar-container">
          <Sidebar
            side="left"
            position="1"
            sidebarType="settings"
            isOpen={$isOpenSettings}
          >
            <div slot="content">
              <Settings />
            </div>
            <div slot="name">Settings</div>
          </Sidebar>
        </div>
        <button
          class="toggle-button button-projects"
          style="position: relative; {$isOpenProjects
            ? 'background-color: var(--surface-primary-active) !important;'
            : 'background-color: transparent;'}"
          on:click={() => {
            toggleProjects()
          }}
        >
          Projects
        </button>
        <button
          class="toggle-button button-projects"
          style="position: relative; {$isOpenSettings
            ? 'background-color: var(--surface-primary-active) !important;'
            : 'background-color: transparent;'}"
          on:click={() => {
            toggleSettings()
          }}
        >
          Settings
        </button>

        <button
          class="toggle-button button-projects"
          style="position: relative; {$isOpenSettingsDiff
            ? 'background-color: var(--surface-primary-active) !important;'
            : 'background-color: transparent;'}"
          on:click={() => {
            toggleSettingsDiff()
          }}
          ><div class="horiz-container">
            Diff
            <div
              class="render-queue-count"
              style="margin-left: 14px; width: 50px;"
            >
              {#if $settingsDiff?.changes}
                {Object.keys($settingsDiff?.changes).length > 0
                  ? Object.keys($settingsDiff?.changes).length
                  : ''}
              {/if}
            </div>
          </div>
        </button>
        {/if}
      </div>
      
      <div class="nav-element">
        
        <Version />

        <Login />
      </div>
      
      <div class="nav-element">
        {#if $isLoggedIn}
        <RenderQModal />
        <SettingsModal />
        {/if}
      </div>
      
    </nav>
    {#if $isLoggedIn}
    <div class="layout">
      <div class="main-container">
        <VideoPreview />
        <div
          class="horiz-container"
          style=" background-color: var(--surface-primary);"
        >
          <!-- {#if $activeTimeline}
            <TimelineControls bind:timeline={$activeTimeline} width={60} />
          {/if} -->
          <TimelineList />
        </div>
      </div>
    </div>
    {/if}
  </div>
</main>

<style>
  nav {
    height: 51px;
    background-color: var(--surface-primary);
    z-index: 290;
    justify-content: flex-start;
    display: flex;
    justify-content: space-between;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 15px;
    color: var(--text-primary);
    border-bottom: 1px solid #000;
  }

  .nav-element {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button-projects {
    margin-bottom: 0;
    margin-left: 10px;
    padding: 15px;
  }

  .toggle-button {
    background: transparent;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 500;
  }

  main {
    text-align: center;
    background-color: #121212;
    color: #e0e0e0;
    font-family: 'Lato', sans-serif;
  }

  .main-container {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
  }

  .logo {
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 40;
    margin-left: 20px;
    width: 80px;
  }

  .project-title {
    margin: 25px 0 0 25px;
    text-align: left;
  }

  .sidebar-container {
    height: 100%;
    position: absolute;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }

  .layout {
    height: calc(100vh - 52px);
    position: relative;
    background: var(--background);
  }

  /* Main container for layout */
  .vert-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
  }

  .horiz-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    justify-content: center;
  }

  .choose-file {
    border: 1px solid #96959c;
    border-radius: 4px;
    color: #96959c;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 4px 10px;
    height: 26px;
  }

  .choose-file:hover {
    border: 1px solid #f8f8f1;
    color: #f8f8f1;
  }

  .choose-file:active,
  .choose-file:not(:disabled):active {
    border: 1px solid var(--text-action200);
    color: var(--text-action200);
    background-color: transparent;
  }

  .hidden {
    display: none; /* Hide the actual file input */
  }
  .render-queue-count {
    color: #baae3e;
    &:after {
      content: '●';
      margin-left: 3px;
    }
  }
  .disabled {
    color: gray;
  }
</style>
