import axios from 'axios'
import { auth } from '../stores/auth'
import { backendUrl } from '../stores/urls'

export async function register(email, password) {
  const response = await axios.post(`${backendUrl}/register`, {
    email,
    password,
  })
  return response.data
}

export async function login(email, password) {
  const response = await axios.post(`${backendUrl}/login`, {
    email,
    password,
  })
  const { access_token } = response.data
  auth.set({
    isAuthenticated: true,
    token: access_token,
    email: { email },
  })
  localStorage.setItem('token', access_token)
}

export function logout() {
  auth.set({
    isAuthenticated: false,
    token: null,
    email: null,
  })
  localStorage.removeItem('token')
}

export function getToken() {
  return localStorage.getItem('token')
}

export const getUser = async () => {
  try {
    const response = await axios.get(`${backendUrl}/user`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    return response.data
    
  } catch (error) {
    console.error(error)
    return false
  }
}