import {
  settingTypes as magov1SettingTypes,
  settingLabels as magov1SettingLabels,
  settings_tabs as magov1SettingsTabs,
} from '../data/magov1/data'
import {
  settingTypes as magov2SettingTypes,
  settingLabels as magov2SettingLabels,
  settingsTabs as magov2SettingsTabs,
} from '../data/magov2/data'

export const renderEngineDict = {
  MagoV1: 'magov1',
  MagoV2: 'magov2',
}

export const renderSettingsLabels = {
  magov1: magov1SettingLabels,
  magov2: magov2SettingLabels,
}

export const renderSettingsTypes = {
  magov1: magov1SettingTypes,
  magov2: magov2SettingTypes,
}

export const renderSettingsTabs = {
  magov1: magov1SettingsTabs,
  magov2: magov2SettingsTabs,
}
