<script>
  import Layer from './Layer.svelte'
  export let timeline = {}
  import { uploadFile } from '../services/assetService'
  import {
    activeLayer,
    controlsWidth,
    timelineScaledWidthPx,
    timelineMaxFrames,
    videoUploadProgress
  } from '../stores/timeline'
  import { settings, queue } from '../../stores'
  import { updateLayer } from '../services/layerService'
  import { isOpenSettings } from '../stores/renderSettings'
  import { activeProject } from '../stores/project'
  import { selectLayer } from '../controllers/layerController'
  import { getInitSettings } from '../../controllers/settingsController'
  import {
    addLayerOnClone,
    addLayerOnVideo,
  } from '../controllers/layerController'
    import { onMount } from 'svelte'

  function updateTimelineMaxFrames(timeline) {
    let newMaxFrames = $timelineMaxFrames
    if (!timeline?.layers.length) {
      return
    }
    const layers = timeline.layers
    for (let i = 0; i < layers.length; i++) {
      const duration = layers[i]?.data?.duration || 0
      newMaxFrames = Math.max(newMaxFrames, duration)
    }
    console.log('timelineMaxFrames, newMaxFrames', $timelineMaxFrames, newMaxFrames)
    $timelineMaxFrames = newMaxFrames
 
  }

  let uploadInprogress = false 
  async function handleUpload(event) {
    uploadInprogress = true 
    $videoUploadProgress = 0
    timeline = await uploadFile(event, timeline, null)
    updateTimelineMaxFrames(timeline)
    console.log('timeline', timeline)
    uploadInprogress = false 
  }

  async function handleStylize(parentLayer) {
    await selectLayer(parentLayer)
    // if stylize is clicked on a video, we make and empty render layer with settings. if it's an older render layer with 0 clips - you can edit it
    // if it's a render layer with >0 clips, you can't edit, only clone
    $isOpenSettings = $isOpenSettings
    if (parentLayer.type == 'RENDER') {
      if ($settings != parentLayer.data['render_settings']) {
        $settings = parentLayer.data?.render_settings || getInitSettings()
        $settings.video_init_path = parentLayer.video_init_path
        parentLayer.data['render_settings'] = $settings
        parentLayer = await updateLayer(parentLayer)
      }
    } else if (parentLayer.type == 'IMAGE') {
      // make an empty layer and switch to that
      addLayerOnVideo(parentLayer)
    }
  }

  async function handleClone(parentLayer) {
    await selectLayer(parentLayer)
    // if stylize is clicked on a video, we make and empty render layer with settings. if it's an older render layer with 0 clips - you can edit it
    // if it's a render layer with >0 clips, you can't edit, only clone
    addLayerOnClone(parentLayer)
  }

  
  let draggedLayerId; // Store the ID of the dragged layer

  function handleDragStart(layerId) {
    draggedLayerId = layerId; // Set the dragged layer's ID
    console.log("Dragged Layer ID:", draggedLayerId);
  }

  function handleDrop(targetLayerId) {
    console.log('draggedLayerId, targetLayerId', draggedLayerId, targetLayerId)
    if (!timeline?.layers) {return}
    let layers = timeline?.layers
    const draggedIndex = layers.findIndex(layer => layer.id === draggedLayerId); // Find the index of the dragged layer
    const targetIndex = layers.findIndex(layer => layer.id === targetLayerId); // Find the index of the target layer

    if (draggedIndex === -1 || targetIndex === -1) return; // Safety check

    // Remove the dragged layer and insert it at the new position
    const [draggedLayer] = layers.splice(draggedIndex, 1); // Remove the dragged layer
    layers.splice(targetIndex, 0, draggedLayer); // Insert at the new position
    console.log(draggedLayer.layer_order)
    // Recalculate the layer_order for all layers after reordering
    layers.forEach((l, index) => {
      l.layer_order = index + 1
    })
    console.log(draggedLayer.layer_order)
    // draggedLayerId = null; // Reset the dragged layer ID
    timeline.layers = layers
    updateLayer(draggedLayer)
  }

  function handleDragOver(event) {
    event.preventDefault(); // Necessary to allow dropping
  }


  $: $activeProject, selectLayer($activeLayer)

  onMount(()=>{
    updateTimelineMaxFrames(timeline)
  })

  //todo: schange main width to fixed; dynamically change layer widths
</script>

<main
  class="mago-timeline"
  id="timeline-container"
  style="width: {$timelineScaledWidthPx}px; background-color: var(--surface-primary);"
>
  {#if timeline.layers?.length > 0}
    {#each timeline.layers.sort((a, b) => {
      // First compare by layer_order (ascending)
      if (a.layer_order !== b.layer_order) {
          return a.layer_order - b.layer_order;
      }
      // If layer_order is the same, compare by created_at (descending)
      return new Date(b.created_at) - new Date(a.created_at);
  }) as layer}
      <Layer
        bind:layer
        onclick={() => selectLayer(layer)}
        onStylize={() => handleStylize(layer)}
        onClone={() => handleClone(layer)}
        onDragOver={(event) => handleDragOver(event)}
        onDragStart={() => handleDragStart(layer.id)}
        onDrop={() => handleDrop(layer.id)}
      />
    {/each}
  
  {:else}
  <!-- For now we don't allow adding anything to layers other than the 1st one -->
  <div
    class="mago-layer buttons"
    style="padding-left: 250px; padding-bottom: 50px;"
  >
    <div>
      <label class="mago-button" style="width: 170px;">
        {#if uploadInprogress}
        Uploading: {$videoUploadProgress}%
        {:else}
        Add Video ➕ 
        {/if}
        
        <input
          type="file"
          class="hidden"
          id="file"
          accept="video/*"
          on:change={handleUpload}
          disabled = {uploadInprogress}
        />
      </label>
    </div>
  </div>
  {/if}
</main>

<style>
  .mago-layer {
    height: 20px;
  }

  .hidden {
    display: none; /* Hide the actual file input */
  }

  .buttons {
    width: 50px; /* Fixed width of the buttons */
    position: sticky;
    left: 0;
    z-index: 10;
  }
</style>
