<script>
  import { onMount } from 'svelte'

  let textArea
  export let value = ''
  export let saveSettings = null
  export let label = ''
  export let class1 = ''
  export let disabled = false
  export let handleFocus
</script>

<textarea
  bind:value
  on:blur={saveSettings}
  bind:this={textArea}
  placeholder={label}
  class="form-control {disabled ? 'disabled':''}"
  {disabled}
  on:focus={handleFocus}
></textarea>

<style>
  textarea {
    field-sizing: content;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 15px; /* Spacing between form elements */
  }

 

  .disabled{
    cursor:default;
    color: gray;
    border: none;
    border-color: rgba(0,0,0,0);
    background-color:var(--surface-primary);
  }
  .disabled:hover { 
    
    color: gray;
    border: none;
    border-color: rgba(0,0,0,0);
  }
</style>
