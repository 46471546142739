import { get } from 'svelte/store'
import { appSettings } from '../stores/appSettings'

import {
  activeTimeline,
  selectedLayers,
  activeLayer,
  settingsDiff,
  
} from '../stores/timeline'
import { settings } from '../../stores'

import { isOpenSettings } from '../stores/renderSettings'
import { compareJsonObjects } from '../helpers/renderSettings'

import { addLayer, cloneLayer } from '../services/layerService'

import {
  prepareSettingsForSaving,
  onLoadSettings,
  setSettings,
  getInitSettings,
  getSettings,
} from '../../controllers/settingsController'

export function selectLayer(layer) {
  if (!layer) {
    return
  }
  activeLayer.set(layer)
  if (event.ctrlKey || event.metaKey) {
    // OR MAC KEY
    selectedLayers.update((a) => a.filter((c) => c.id !== layer.id))
    selectedLayers.set([layer, ...get(selectedLayers)])
    console.log('selected layer ', get(selectedLayers))
  } else {
    selectedLayers.set([layer])
    console.log('selected layer ', layer)
  }
  if (layer?.type == 'IMAGE') {
    settings.set(onLoadSettings(getInitSettings()))
    settings.set({ video_init_path: layer?.clips[0].asset.filepath })
  } else {
    let newsettings = layer?.data?.render_settings

    settings.set(onLoadSettings(newsettings || getInitSettings()))

    activeLayer.set(layer)
    if (get(activeLayer)?.clips < 1) {
      isOpenSettings.set(true)
    }
  }
  if (get(selectedLayers).length >= 2) {
    let json1 = get(selectedLayers)[0]?.data?.render_settings
    let json2 = get(selectedLayers)[1]?.data?.render_settings
    let diff = {}
    let change
    if (json1?.renderEngine != json2?.renderEngine) {
      change = {
        name: 'renderEngine',
        left: json1?.renderEngine || 'None',
        right: json2?.renderEngine || 'None',
      }
    }

    json1 = json1?.renderSettings[json1.renderEngine] || json1
    json2 = json2?.renderSettings[json2.renderEngine] || json2
    diff = compareJsonObjects(json1, json2)
    if (change) {
      diff.changes.push(change)
    }

    diff.names = {
      left: get(selectedLayers)[0].name,
      right: get(selectedLayers)[1].name,
    }
    settingsDiff.set(diff)
    console.log('settingsDiff', get(settingsDiff))
  } else {
    settingsDiff.set(null)
  }
}

function mapFiles(files){
  return files.map(
    (file) => ({
      name: file,
      label: file,
    })
  )
}

export async function addLayerOnVideo(parentLayer) {
  let clean_settings = prepareSettingsForSaving(getInitSettings())
  clean_settings['video_init_path'] = parentLayer.id
  let checkpoints = mapFiles(get(appSettings)?.filenames['checkpoint_dir'])
  let vaes = mapFiles(get(appSettings)?.filenames['vae_dir'])
  console.log('addLayerOnVideo files', checkpoints, vaes, clean_settings)
  if (checkpoints.length>0){
    clean_settings['renderSettings'][clean_settings.renderEngine]['model_path'] = checkpoints[0]['name']
  }
  if (vaes.length>0 && clean_settings['renderSettings'][clean_settings.renderEngine]?.model_version === 'control_multi_sdxl'){
    clean_settings['renderSettings'][clean_settings.renderEngine]['vae_path'] = vaes[0]['name']
  }
  console.log('clean settings', clean_settings)
  let res = await addLayer(get(activeTimeline), 'render', parentLayer, {
    render_settings: clean_settings,
    sources: {
      video: parentLayer.clips[0].asset,
    },
  })
  console.log('res', res)
  activeLayer.set(res)
  console.log('clean settings', clean_settings)
  console.log('new activeLayer', get(activeLayer))
  let tempActiveTimeline = get(activeTimeline)

  tempActiveTimeline.layers.splice(parentLayer.layer_order, 0, get(activeLayer));
  tempActiveTimeline.layers.forEach((layer, index) => {
    layer.layer_order = index + 1;
  });

  // tempActiveTimeline.layers = [...tempActiveTimeline.layers, get(activeLayer)]
  activeTimeline.set(tempActiveTimeline)
  console.log('new layer')
  console.log(clean_settings)
  setSettings(onLoadSettings(clean_settings))

  console.log('newsettings', getSettings())

}

export async function addLayerOnClone(parentLayer) {
  let res = await cloneLayer(parentLayer)
  console.log('res', res)
  activeLayer.set(res)
  let tempActiveTimeline = get(activeTimeline)

  tempActiveTimeline.layers.splice(parentLayer.layer_order, 0, res);
  tempActiveTimeline.layers.forEach((layer, index) => {
    layer.layer_order = index + 1;
  });

  // tempActiveTimeline.layers = [...tempActiveTimeline.layers, res]
  activeTimeline.set(tempActiveTimeline)
  console.log('clone layer')
  let newsettings = res?.data?.render_settings || getInitSettings()
  setSettings(onLoadSettings(newsettings))
  console.log('newsettings', getSettings())
}