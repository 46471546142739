<script>
  import { onMount } from 'svelte'
  import axios from 'axios'

  import { backendUrl } from '../stores/urls'
  let version

  const fetchVersion = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/version`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      version = response.data.version
    } catch (error) {
      console.error(error)
    }
  }

  onMount(fetchVersion)
</script>

<div>
  Version: {version}
</div>
