<script>
  export let className = ''
</script>

<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class={className}
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.00004 6.53033C2.80478 6.33507 2.80478 6.01849 3.00004 5.82323C3.1953 5.62796 3.51188 5.62796 3.70714 5.82323L8.00004 10.1161L12.2929 5.82323C12.4882 5.62796 12.8048 5.62796 13 5.82323C13.1953 6.01849 13.1953 6.33507 13 6.53033L8.00004 11.5303L3.00004 6.53033Z"
    fill="#8F8F8F"
  />
</svg>

<style>
  .arrow-icon {
    position: absolute;
    right: 6px;
    top: 25%;
    color: red;
  }
</style>
