<script>
  import { onMount } from 'svelte'

  export let maxFrames = 19 // Default value for demonstration
  let notches = []
  export let width
  export let minDist = 5 // minimum disatance between notches in px;
  let dist = width / maxFrames
  let distScale = Math.ceil(minDist / dist)

  function make_notches(maxFrames) {
    dist = width / maxFrames
    distScale = Math.ceil(minDist / dist)
    notches = []
    let step = 1

    for (let i = 0; i < maxFrames + 1; i++) {
      notches.push({
        value: i,
        left: `${(i / maxFrames) * 100}%`,
      })
    }
  }

  onMount(() => make_notches(maxFrames))

  $: width, make_notches(maxFrames)
</script>

<div class="ruler" style="width:{width}px">
  {#each notches as notch}
    {#if notch.value % (10 * distScale) === 0}
      <div class="label" style="left: {notch.left};">{notch.value}f</div>
      <div class="notch" style="left: {notch.left}; height: 20px"></div>
    {:else}
      <div
        class="notch"
        style="left: {notch.left}; height: 10px; top: 40px;"
      ></div>
    {/if}
  {/each}
</div>

<style>
  .ruler {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 50px;
    position: relative;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mark {
    height: 40px;
    width: 2px;
    background-color: gray;
    position: relative;
  }

  .label {
    top: 20px;
    transform: translateX(-50%);
    font-size: 12px;
  }

  #notch-container {
    width: 100%;
    height: 50px;
    background-color: #363636;
    margin: 20px 0;
    position: relative;
  }

  .notch {
    height: 10px;
    width: 2px;
    background-color: #393939;
    position: absolute;
    top: 0;
    top: 35px;
  }

  .label {
    position: absolute;
    color: rgb(166, 166, 166) 0;
    font-size: 10px;
    transform: translateX(-50%);
    white-space: nowrap;
  }
</style>
