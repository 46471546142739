<script>
  import { onMount } from 'svelte'
  import {
    hasFrames,
    calcFrames,
    downloadVideo,
    downloadFrames,
  } from '../helpers/layer'
  import Download from '../../icons/Download.svelte'
  
  export let layer

  let ETA = 0
  let oldProgress = null

  function updateETA() {
    const asset = layer?.clips[0]?.asset
    let progress = asset?.progress

    if (!progress) {
      return
    }

    if (progress == oldProgress && ETA > 1000) {
      ETA = ETA - 1000
      return
    } else {
      oldProgress = progress
      ETA = asset?.ETA * 1000
    }
  }

  function ETA2String(ETA) {
    ETA = new Date(ETA)
    const days = Math.floor(ETA.getTime() / (1000 * 60 * 60 * 24))
    const hours = String(ETA.getUTCHours()).padStart(2, '0')
    const minutes = String(ETA.getUTCMinutes()).padStart(2, '0')
    const seconds = String(ETA.getUTCSeconds()).padStart(2, '0')
    ETA = `${days > 0 ? days + 'd' : ''} ${hours}:${minutes}:${seconds}`
    return ETA
  }

  function showDone() {
    if (!layer?.clips[0]?.asset?.show_done) {
      return false
    }
    return layer.clips[0].asset.show_done
  }



  onMount(() => {
    setInterval(updateETA, 1000)
  })
</script>

{#if layer?.type == 'RENDER' && layer?.clips.length > 0 && showDone()}
  <div class="layer-name horiz-container">
    <div class="render-queue-count green"></div>
    <div
      style="color: #57BA3E;
;"
    >
      Done
    </div>
  </div>
{/if}
{#if layer?.type == 'RENDER' && layer?.clips.length > 0 && layer.clips[0].asset.status != 'FINISHED'}
  <div class="layer-name horiz-container">
    {#if layer.clips[0].asset.status == 'PENDING'}
      <div class="render-queue-count gray"></div>
      <div
        style="color:  #8F8F8F;
"
      >
        Waiting ({layer.clips[0].asset.order}) ({layer.clips[0].asset.display_order})
      </div>
    {:else if layer.clips[0].asset.status == 'ERROR'}
      <div class="render-queue-count red"></div>
      <div style="color: red;">Error</div>
    {:else if layer.clips[0].asset.status == 'INPROGRESS'}
      <div class="render-queue-count pulse"></div>
      <div
        style="color: #A6C018;
;"
      >
        {#if layer.clips[0].asset.progress == 0}
          Starting...
        {:else}
          {layer.clips[0].asset.progress}% {ETA2String(ETA)}
        {/if}
      </div>
    {/if}
  </div>
{/if}
{#if layer.type == 'RENDER'}
  {#if layer?.type == 'RENDER' && hasFrames(layer)}
    {#if layer?.clips[0].asset?.status != 'FINISHED' || calcFrames(layer?.clips[0].asset?.frame_range) == 1 || layer?.clips[0].asset?.data?.parent_render}
      <span
        on:click={() => downloadFrames(layer)}
        style="cursor: pointer; margin:auto;"><Download /></span
      >{/if}
    {#if layer?.clips[0].asset?.status == 'FINISHED' && calcFrames(layer?.clips[0].asset?.frame_range) != 1 && !layer?.clips[0].asset?.data?.parent_render}<span
        on:click={() => downloadVideo(layer)}
        style="cursor: pointer; margin-top:8px;"><Download /></span
      >{/if}

  {/if}
{/if}

<style>
  .render-queue-count {
    color: #baae3e;
    width: 20px;
    &:after {
      content: '●';
    }

    opacity: 1;
  }
  .red {
    color: red;
  }
  .green {
    color: green;
  }
  .gray {
    color: gray;
  }

  .pulse {
    animation: pulse-animation 2s infinite;
  }

  @keyframes pulse-animation {
    0% {
      text-shadow:
        0 0 2px #baae3e,
        0 0 5px #baae3e,
        0 0 7px #baae3e;
      opacity: 1;
    }
    50% {
      text-shadow:
        0 0 1px #494949,
        0 0 2px #424242,
        0 0 3px #363636;
      opacity: 1;
      color: gray;
    }
    100% {
      text-shadow:
        0 0 2px #baae3e,
        0 0 5px #baae3e,
        0 0 7px #baae3e;
      opacity: 1;
    }
  }

  .layer-name {
    background-color: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(5px);
    margin: 3px;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
  }
</style>
