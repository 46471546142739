<script>
  import { onMount } from 'svelte'
  export let value = null
  export let saveSettings = null
  export let handleFocus
  export let label = ''
  import Inputs from './Inputs.svelte'

  import {
    controlnetModelInputTypes,
  } from '../data/magov1/data.js'

  if (value == null) {
    value = {}
  }

  export let disabled = false

  function controlSaveSettings() {
    saveSettings()
  }

  function ensureValueStructure() {
    if (!value || value == {}) {
      value = {
        enabled: false,
        weight: 1,
        start: 0,
        end: 1,
        mode: 'global',
        source: 'global',
        resolution: -1,
        preprocess: 'global',
      }
    }
  }

  onMount(ensureValueStructure)
  $: label, ensureValueStructure()
  $: value, ensureValueStructure()
</script>

<div>
  <div
    class="vert-container"
    style="{value.enabled
      ? 'border: 2px solid gray;'
      : ''} margin: 5px auto; padding: 2px;"
  >
    <div class="horiz-container">
      <Inputs
        input_type="checkbox"
        {label}
        bind:value={value.enabled}
        saveSettings={controlSaveSettings}
        {disabled}
        on:focus={handleFocus}
      />
      {#if value.enabled}
        <Inputs
          input_type="checkbox"
          label="Custom source"
          bind:value={value.enable_custom_source}
          saveSettings={controlSaveSettings}
          style="margin-left: 10px;"
          {disabled}
          on:focus={handleFocus}
        />
      {/if}
    </div>
    <div class="horiz-container">
      {#if value.enabled}
        <Inputs
          input_type="float"
          label="weight"
          bind:value={value.weight}
          saveSettings={controlSaveSettings}
          style="width: 50px;"
          {disabled}
          on:focus={handleFocus}
        />
        <Inputs
          input_type="float"
          label="start"
          bind:value={value.start}
          saveSettings={controlSaveSettings}
          style="width: 50px;"
          {disabled}
          on:focus={handleFocus}
        />
        <Inputs
          input_type="float"
          label="end"
          bind:value={value.end}
          saveSettings={controlSaveSettings}
          style="width: 50px;"
          {disabled}
          on:focus={handleFocus}
        />
        <Inputs
          input_type="int"
          label="resolution"
          bind:value={value.resolution}
          saveSettings={controlSaveSettings}
          style="width: 100px;"
          {disabled}
          on:focus={handleFocus}
        />
        <Inputs
          input_type={controlnetModelInputTypes.mode}
          label="mode"
          bind:value={value.mode}
          saveSettings={controlSaveSettings}
          {disabled}
          on:focus={handleFocus}
        />
      {/if}
    </div>
    <div class="horiz-container">
      {#if value.enabled && value.enable_custom_source}
        <Inputs
          input_type="textarea"
          label="source"
          bind:value={value.source}
          saveSettings={controlSaveSettings}
          {disabled}
          on:focus={handleFocus}
        />
        <Inputs
          input_type={controlnetModelInputTypes.preprocess}
          label="preprocess"
          bind:value={value.preprocess}
          saveSettings={controlSaveSettings}
          {disabled}
          on:focus={handleFocus}
        />
      {/if}
    </div>
  </div>
</div>

<style>
  .vert-container {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space items evenly between the main axis */
    flex-direction: column;
    justify-content: center;
  }

  .horiz-container {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space items evenly between the main axis */
    flex-direction: row;
    justify-content: left;
  }
</style>
