<script>
  export let clip
  import {
    timelineMaxFrames,
    timelineWidthPx,
    timelineScaledWidthPx,
    timelineScrollOffset,
    controlsWidth,
  } from '../stores/timeline'
  const clipMinDuration = 50
  import { queue } from '../../stores'
  export let layer
  export let onDeleteClip
  import { fetchImageWithToken } from '../services/previewService'
  import { onMount, onDestroy } from 'svelte'
  import ProgressBar from './ProgressBar.svelte'
  import { activeProject } from '../stores/project'

  let imgUrl = ''

  function scaleToMaxHeight([h, w], maxh) {
    const scaleFactor = maxh / h
    const newHeight = maxh
    const newWidth = w * scaleFactor

    return [newHeight, newWidth]
  }

  function calcFrameRange() {
    const offsetPx = $timelineScrollOffset
    const thumbSizePx = $timelineWidthPx
    const totalFramesFr = Math.min($timelineMaxFrames, clip?.data?.duration)
    const timelineSizePx = $timelineScaledWidthPx
    const frameSizePx = timelineSizePx / totalFramesFr

    let firstframeFr = (offsetPx / frameSizePx).toFixed(0)
    let lastFrameFr = Math.min(
      ((offsetPx + thumbSizePx) / frameSizePx).toFixed(0),
      clip?.data?.duration
    )
    let thumbTotalFramesFr = lastFrameFr - firstframeFr
    const sourceFrameSizePx = scaleToMaxHeight(
      clip.asset?.data?.size || [64, 64],
      68
    )
    const sourceFrameWidthPx = sourceFrameSizePx[1]
    const maxsizePx = Math.max(...sourceFrameSizePx).toFixed(0)
    const showFramesPerThumbFr = (thumbSizePx / sourceFrameWidthPx).toFixed(0)
    let throttleFr = (thumbTotalFramesFr / showFramesPerThumbFr).toFixed(0)

    // console.log('firstframeFr, lastFrameFr,  maxsizePx, throttleFr')
    // console.log(firstframeFr, lastFrameFr, maxsizePx, throttleFr)
    throttleFr = Math.max(1, throttleFr)
    return [firstframeFr, lastFrameFr, maxsizePx, throttleFr]
  }

  function scaleX(x, timelineScaledWidthPx) {
    timelineScaledWidthPx -= controlsWidth
    x = (x * timelineScaledWidthPx) / $timelineMaxFrames
    return x
  }

  let lastExecution = 0

  async function calcThumbAndFetch() {
    const now = Date.now()
    if (now - lastExecution < 50) return // throttle every 50ms
    lastExecution = now
    // console.log('onmount')
    let [firstframeFr, lastFrameFr, maxsizePx, throttleFr] = calcFrameRange()
    let offset = clip?.data?.timeline_offset || 0
    // `thumb/clip/${clip.id}/0/${clip.data?.duration}/128/50/0`,
    imgUrl = await fetchImageWithToken(
      `thumb/clip/${clip.id}/${firstframeFr+offset}/${lastFrameFr+offset}/${maxsizePx}/${throttleFr}/0`
    )
  }
  let img
  let observer = null
  let oldProgress
  let oldStatus

  onMount(() => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          calcThumbAndFetch()
          observer.unobserve(entry.target)
        }
      })
    })

    observer.observe(img)
  })

  onDestroy(() => {
    observer.disconnect()
  })

  function checkObserver(fn) {
    if (
      clip?.asset?.progress !== oldProgress ||
      clip?.asset?.status !== oldStatus
    ) {
      oldProgress = clip?.asset?.progress
      oldStatus = clip?.asset?.status
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fn()
            observer.unobserve(entry.target)
          }
        })
      })
      if (img){observer.observe(img)}
      
    }
  }


  $: clip?.asset?.progress,
    clip?.asset?.status,
    checkObserver(calcThumbAndFetch)

  function updateRenderStatus(queue) {
    let matchedItem = queue.find(
      (queueItem) =>
        clip.asset.id === queueItem.asset_id ||
        clip.asset?.data?.parent_render === queueItem.asset_id
    )
    if (matchedItem) {
      // console.log('matched item', matchedItem)
      clip.asset['progress'] = matchedItem['progress']
      
      if (clip.asset['status'] !== matchedItem['status'] && matchedItem['status'] === 'FINISHED'){
        calcThumbAndFetch()
      }
      clip.asset['status'] = matchedItem['status']
      clip.asset['ETA'] = matchedItem['ETA']
      clip.asset['render_started_at'] = matchedItem['render_started_at']
      clip.asset['frame_range'] = matchedItem['frame_range']
      clip.asset['finished_at'] = matchedItem['finished_at']
      clip.asset['show_done'] = matchedItem['show_done']
      clip.asset['error'] = matchedItem['error']
      clip.asset['server_error'] = matchedItem['server_error']
      clip.asset['order'] = matchedItem['order']
      clip.asset['display_order'] = matchedItem['display_order']
      if (matchedItem['status'] === 'ERROR'){
        console.log('matchedItem[status] === ERROR', matchedItem)
      }
      // console.log(clip)
    }
  }

  function thumbIsVisible(clip) {
    // only show thumb if there are rendered frames or for video
    if (clip?.asset?.type == 'VIDEO') {
      return true
    }
    if (
      ['INPROGRESS', 'FINISHED', 'ERROR'].includes(clip?.asset?.status) &&
      clip?.asset?.progress > 1
    ) {
      // console.log('thumb true')
      return true
    }
    // console.log('thumb false')
    return false
  }

  $: $activeProject, updateRenderStatus($queue)
</script>

<div
  class="clip {clip?.asset?.type == 'VIDEO'
    ? 'video'
    : 'render'} tooltip-container"
  style="margin-left: {scaleX(
    clip?.data?.timeline_offset,
    $timelineScaledWidthPx
  ) || 0}px; width: {scaleX(
    clip?.data?.duration || clipMinDuration,
    $timelineScaledWidthPx
  )}px;"
>
  {#if clip?.asset?.type == 'RENDER'}
    {#if clip?.asset?.status == 'INPROGRESS'}
      <div style="position: absolute; left: 0px; width: 100%; height: 100%;">
        <ProgressBar
          progress={clip?.asset?.progress || 0}
          animateGray={false}
        />
      </div>
    {/if}
  {/if}
  {#if clip?.asset?.error?.error_message}
  <span class="tooltip-text vert-container"
    >{JSON.stringify(clip?.asset?.error?.error_type, null, 4)}
  </span>
  {/if}
  {#if !thumbIsVisible(clip)}
    <div
      style="background-color: rgba(10,10,10,0); position: absolute; left:0, top:0; width:100%; height:100%;"
    ></div>
  {/if}
  <img
    bind:this={img}
    src={imgUrl}
    alt={clip.name}
    style="opacity:{thumbIsVisible(clip) ? 100 : 0};"
  />
</div>

<style>
  .clip {
    border: 1px solid var(--text-tertiary);
    display: flex;
    justify-content: end;
    position: relative;
    height: 68px;
  }
  .render img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; /* Same as the container */
  }
  .render {
    background-color: var(--elements-primary);
    border-radius: 8px;
  }
  .video {
    background-color: green;
    border-radius: 8px;
  }

  .video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px; /* Same as the container */
  }

  .delete-clip {
    position: absolute;

    right: 5px;
    background-color: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(5px);
    z-index: 10;
    margin: 3px;
    padding: 8px;
    border-radius: 8px;
    font-size: 12px;
  }
  .onhover:hover {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
