<script>
  import { getContext } from 'svelte'
  const { registerTab, activeTab } = getContext('tab-context')
  import { onMount, setContext, beforeUpdate, afterUpdate } from 'svelte'

  export let label

  // Register this tab and get its index
  let index
  onMount(() => {
    index = registerTab(label)
    console.log('index', index, label)
  })

  // Reactive visibility
  $: isVisible = $activeTab === index
</script>

{#if isVisible}
  <slot />
{/if}
